import React from 'react';

function FeaturesBlocks() {
  return (
    <section id="info">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4 text-gray-900 dark:text-white">Making practice more efficient and fun</h2>
            <p className="text-xl text-gray-400">Our mission is to help you expedite your music learning process, so you can focus more on making music than simply performing technique. We achieve this through 3 primary features.</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none" data-aos-id-blocks>

            {/* 1st item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
              <div className="flex items-center justify-center w-16 h-16 rounded-full bg-violet-600 mb-4">
                <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="stroke-current text-violet-100" strokeLinecap="square" strokeWidth="24" fill="none" d="M499.1 6.3c8.1 6 12.9 15.6 12.9 25.7v72V368c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V147L192 223.8V432c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V200 128c0-14.1 9.3-26.6 22.8-30.7l320-96c9.7-2.9 20.2-1.1 28.3 5z" /></svg>
              </div>
              <h4 className="h4 mb-2 text-gray-900 dark:text-white">Music library</h4>
              <p className="text-lg text-gray-400 text-center">We have curated a library of hundreds of music pieces of all genres and composers for your practice. Find new music and play!</p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]">
              <div className="flex items-center justify-center w-16 h-16 rounded-full bg-violet-600 mb-4">
                <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="stroke-current text-violet-100" strokeLinecap="square" strokeWidth="24" fill="none" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
              </div>
              <h4 className="h4 mb-2 text-gray-900 dark:text-white">Sight-reading</h4>
              <p className="text-lg text-gray-400 text-center">Use our advanced music filters and search capability to find the perfect pieces for your sight-reading practice. Embrace musical exploration!</p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]">
              <div className="flex items-center justify-center w-16 h-16 rounded-full bg-violet-600 mb-4">
                <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path className="stroke-current text-violet-100" strokeLinecap="square" strokeWidth="24" fill="none" d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
              </div>
              <h4 className="h4 mb-2 text-gray-900 dark:text-white">Exercises</h4>
              <p className="text-lg text-gray-400 text-center">Our exercises feature, built-in to our practice interface, is designed to maximize the efficiency and fun of your practice in a more structured environment. Learn <i>subito</i>!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
