import API from ".";

const getAlbumData = (user, id, mix = false) => {

  let url = '/getAlbumData?album_id=' + id + '&mix=' + (mix ? '1' : '0');
  return new API(user).getRequest(url).then(json => {
    return json;
  });
}

export default getAlbumData;