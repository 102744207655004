function SubitoLogo(props) {

  const { notext } = props;

  if (notext) {
    return (
      <img className="h-12" src="/SubitoLogoNoText.png" />
    )
  }

  return (
    <img className="h-12" src="/SubitoLogo.png" />
  )
}

export default SubitoLogo