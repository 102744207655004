export const createImageFromInitials = (size, name, color) => {
  if (name == null) return;

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  canvas.width = canvas.height = size

  // Fill the background with the provided color
  context.fillStyle = color
  context.fillRect(0, 0, size, size)

  // Set text color to white
  context.fillStyle = "#ffffff";
  context.textBaseline = 'middle'
  context.textAlign = 'center'
  context.font = `${size / 2}px Segoe UI`
  context.fillText(name, (size / 2), (size / 2))

  return canvas.toDataURL()
};