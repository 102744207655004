export default function NoResults() {
  return (
    <div className="w-full max-w-2xl mx-auto my-8">
      <div className="relative">
        <div className="flex flex-col items-center justify-center p-8 md:p-12 space-y-6">
          <div className="relative">
            <div className="absolute inset-0 bg-violet-100 dark:bg-violet-900/20 rounded-full blur-xl opacity-50" />
            <svg
              className="w-24 h-24 md:w-32 md:h-32 text-violet-600 dark:text-violet-400 relative"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="2"
              clipRule="evenodd"
              viewBox="0 0 32 32"
            >
              <path d="M22.456,23.871l6.837,6.836c0.39,0.39 1.024,0.39 1.414,0c0.39,-0.39 0.39,-1.024 0,-1.414l-6.836,-6.837c1.95,-2.273 3.129,-5.228 3.129,-8.456c-0,-7.175 -5.825,-13 -13,-13c-7.175,0 -13,5.825 -13,13c-0,7.175 5.825,13 13,13c3.228,0 6.183,-1.179 8.456,-3.129Zm-8.456,-20.871c6.071,0 11,4.929 11,11c-0,6.071 -4.929,11 -11,11c-6.071,0 -11,-4.929 -11,-11c-0,-6.071 4.929,-11 11,-11Z" />
              <path d="M15,13.001c-0.836,-0.629 -1.875,-1.001 -3,-1.001c-2.76,0 -5,2.24 -5,5c0,2.76 2.24,5 5,5c2.76,-0 5,-2.24 5,-5c0,-0 -0,-9 -0,-9c0,0 2.4,1.8 2.4,1.8c0.442,0.331 1.069,0.242 1.4,-0.2c0.331,-0.442 0.242,-1.069 -0.2,-1.4c-0,0 -1.286,-0.965 -2.4,-1.8c-0.606,-0.455 -1.417,-0.528 -2.094,-0.189c-0.678,0.339 -1.106,1.031 -1.106,1.789l0,5.001Zm-3,0.999c1.656,0 3,1.344 3,3c-0,1.656 -1.344,3 -3,3c-1.656,-0 -3,-1.344 -3,-3c0,-1.656 1.344,-3 3,-3Z" />
            </svg>
          </div>

          <div className="text-center space-y-3 max-w-md">
            <h3 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white">
              No more pieces!
            </h3>
            <p className="text-base md:text-lg text-gray-600 dark:text-gray-300">
              Please expand your piece filters to continue sightreading.
            </p>
          </div>

          <div className="pt-2">
            <button className="inline-flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 hover:bg-violet-100 rounded-lg transition-colors dark:text-violet-300 dark:bg-violet-900/30 dark:hover:bg-violet-900/50">
              Adjust Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}