import API from ".";
import PieceType from "../types/piece";

const getPiece = (user, id) => {
  let url = '/getPiece?piece_id=' + id;

  return new API(user).getRequest(url).then(json => {
    return new PieceType(json);
  });
}

export default getPiece;