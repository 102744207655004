import React from 'react';

const MusicLibraryLoadingView = () => {
  return (

    <div role="status" class="w-56 p-4 rounded-b-lg border rounded-lg shadow-sm animate-pulse md:p-6 dark:border-gray-600">
      <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-600"></div>
      <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-30 mb-4"></div>
      <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
      <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
      <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-600"></div>
      <div class="flex items-center mt-4">
        <div>
          <div class="w-30 h-2 bg-gray-200 rounded-full dark:bg-gray-600"></div>
        </div>
      </div>
    </div>

  )
}

export default MusicLibraryLoadingView;
