import API from ".";

const saveUserInfo = (user, fname, lname, instrument, pic) => {

  if (!["piano"].includes(instrument)) instrument = "piano"; //"cello", "violin"

  let obj = {
    "fname": fname,
    "lname": lname,
    "instrument": instrument,
    "profile_pic": pic ? pic : ""
  }

  return new API(user).postRequest('/saveUserInfo', obj);
}

export default saveUserInfo;