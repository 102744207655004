import { useState, useEffect, useCallback, useRef } from 'react';

const useContainerSize = (containerRef) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const frameRef = useRef(null);

  const updateSize = useCallback(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setSize({ width, height });
    }
  }, [containerRef]);

  const debouncedUpdateSize = useCallback(() => {
    if (frameRef.current) {
      cancelAnimationFrame(frameRef.current);
    }
    frameRef.current = requestAnimationFrame(() => {
      updateSize();
      frameRef.current = null;
    });
  }, [updateSize]);

  useEffect(() => {
    updateSize(); // Initial size update

    const resizeObserver = new ResizeObserver(() => {
      debouncedUpdateSize();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
      resizeObserver.disconnect();
    };
  }, [containerRef, debouncedUpdateSize]);

  return size;
};

export default useContainerSize;