import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../stores/Account';
import Modal from '../../components/Modal';
import getAlbums from '../../api/getAlbums';
import addPieceToAlbum from '../../api/addPieceToAlbum';

function QueryAlbum({ setSelectedAlbum, selectedAlbum }) {
  const [albums, setAlbums] = useState([]);
  const { user } = useContext(AccountContext);

  useEffect(() => {
    getAlbums(user)
      .then((data) => {
        setAlbums(data.albums);
      })
      .catch((err) => {
        console.error('Error fetching albums:', err);
      });
  }, [user]);

  const handleAlbumSelection = (event) => {
    const selectedAlbumId = event.target.value;
    const album = albums.find(album => album.id === selectedAlbumId);
    setSelectedAlbum(album);
  };

  return (
    <div className="relative w-full">
      <label htmlFor="album-select" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        Select an Album
      </label>
      <select
        id="album-select"
        className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
        value={selectedAlbum ? selectedAlbum.id : ''}
        onChange={handleAlbumSelection}
      >
        <option value="">Choose an album</option>
        {albums.map((album) => (
          <option key={album.id} value={album.id}>
            {album.name}
          </option>
        ))}
      </select>
    </div>
  );
}

function AddToList({ piece_id, button_view, setDisabled }) {
  const [album, setAlbum] = useState(null);
  const { user } = useContext(AccountContext);

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (album && album !== null && 'id' in album && album.id !== "") {
      addPieceToAlbum(user, album.id, piece_id)
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Modal
      onModalClose={() => setDisabled(false)}
      onModalOpen={() => setDisabled(true)}
      title="Add piece to list" button_text="Add piece" submit={submit} button_view={button_view}>
      <QueryAlbum selectedAlbum={album} setSelectedAlbum={setAlbum} />
    </Modal>
  );
}

export default AddToList;