import API from ".";

const getPresignedPost = (user, n) => {
  if (!n || n === null || n === undefined) {
    n = 1;
  }

  let url = '/getPresignedPost?n=' + n.toString();

  return new API(user).getRequest(url).then(json => {
    return json;
  });
}

export default getPresignedPost;