import React from 'react';

const ImageCompressor = (props) => {

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = async () => {
      const img = new Image();
      img.src = reader.result;

      await img.decode();

      const canvas = document.createElement('canvas');
      const MAX_WIDTH = 300;
      const MAX_HEIGHT = 300;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      const quality = 0.6; // Compression quality (80%)
      const compressedImageData = canvas.toDataURL('image/jpeg', quality);

      props.setPic(compressedImageData);
    };

    reader.readAsDataURL(file);
  };

  return (
    <label class={props.className}>
      <input type="file" accept="image/*" onChange={handleImageUpload} className="hidden" />
      Change picture
    </label>
  );
};

export default ImageCompressor;