import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../stores/Account';
import searchMusic from '../../api/searchMusic';

function QueryPiece({ instrument, setSelectedPiece, selectedPiece }) {
  const [results, setResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { user } = useContext(AccountContext);

  useEffect(() => {
    if (searchQuery) {
      searchMusic(user, searchQuery)
        .then((data) => {
          setResults(data.pieces);
        })
        .catch((err) => {
          console.error('Error fetching music:', err);
        });
    } else {
      setResults([]);
    }
  }, [searchQuery, user, instrument]);

  const handlePieceSelection = (piece) => {
    setSelectedPiece(piece);
    setSearchQuery('');
  };

  return (
    <div className="relative w-full">
      <label htmlFor="music-search" className="sr-only">
        Search Music
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <input
          type="text"
          id="music-search"
          className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-violet-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-violet-600 dark:focus:ring-violet-600 dark:focus:border-violet-500"
          placeholder="Search for music"
          value={selectedPiece ? `${selectedPiece.title} - ${selectedPiece.composer_fname} ${selectedPiece.composer_lname}` : searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setSelectedPiece(null);
          }}
        />
      </div>
      {searchQuery && !selectedPiece && (
        <ul className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 shadow-md rounded-lg max-h-48 overflow-y-auto">
          {results.map((item) => (
            <li
              key={item.id}
              className="hover:bg-gray-50 dark:hover:bg-gray-700 p-2 cursor-pointer border-b border-gray-100 dark:border-gray-700"
              onClick={() => handlePieceSelection(item)}
            >
              <div className="font-medium text-gray-900 dark:text-gray-300">
                {item.title}, {item.composer_fname} {item.composer_lname}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default QueryPiece;