import { AccountContext } from '../../stores/Account';
import Modal from '../../components/Modal';
import { useContext, useState, useEffect } from 'react';
import { Label } from '../../components/Input';
import uploadPiece from '../../api/uploadPiece';
import getPresignedPost from '../../api/getPresignedPost';
import putToS3 from '../../api/putToS3';
import bulkPieceUpload from '../../api/bulkPieceUpload';

const FileUploader = ({ pdfFiles, setPdfFiles }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const processFileEntry = async (entry) => {
    if (entry.isFile) {
      return new Promise((resolve) => {
        entry.file(file => {
          if (file.name.toLowerCase().endsWith('.pdf')) {
            resolve([file]);
          } else {
            resolve([]);
          }
        });
      });
    } else if (entry.isDirectory) {
      const dirReader = entry.createReader();
      return new Promise((resolve) => {
        dirReader.readEntries(async (entries) => {
          const filesInDir = await Promise.all(
            entries.map(entry => processFileEntry(entry))
          );
          resolve(filesInDir.flat());
        });
      });
    }
    return [];
  };

  const handleFileSelect = async (event) => {
    setIsProcessing(true);
    const items = event.target.files ||
      (event.dataTransfer?.items ? [...event.dataTransfer.items] : []);

    let allPdfFiles = [];

    for (const item of items) {
      if (item.webkitGetAsEntry) {
        const entry = item.webkitGetAsEntry();
        if (entry) {
          const files = await processFileEntry(entry);
          allPdfFiles = [...allPdfFiles, ...files];
        }
      } else {
        const file = item instanceof File ? item : item.getAsFile();
        if (file && file.name.toLowerCase().endsWith('.pdf')) {
          allPdfFiles.push(file);
        }
      }
    }

    setPdfFiles(prevFiles => [...prevFiles, ...allPdfFiles]);
    setIsProcessing(false);
  };

  const removeFile = (index) => {
    setPdfFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="p-6 max-w-2xl mx-auto">
      <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center mb-6">
        <input
          type="file"
          onChange={handleFileSelect}
          className="hidden"
          id="fileInput"
          multiple
          webkitdirectory=""
          directory=""
        />
        <label
          htmlFor="fileInput"
          className="flex flex-col items-center cursor-pointer"
        >
          {/* Upload Icon */}
          <svg
            className="w-12 h-12 text-gray-400 mb-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>
          <p className="text-lg mb-2">Drag & drop files/folders here</p>
          <p className="text-sm text-gray-500">or click to select</p>
        </label>
      </div>

      {isProcessing && (
        <div className="text-center text-gray-600 my-4">
          Processing files...
        </div>
      )}

      {pdfFiles.length > 0 && (
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-4">
            Selected PDF Files ({pdfFiles.length})
          </h3>
          {pdfFiles.length < 10 && (
            <ul className="space-y-2">
              {pdfFiles.map((file, index) => (
                <li
                  key={`${file.name}-${index}`}
                  className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center">
                    {/* File Icon */}
                    <svg
                      className="w-5 h-5 text-gray-500 mr-3"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="text-sm">{file.name}</span>
                  </div>
                  <button
                    onClick={() => removeFile(index)}
                    className="text-red-500 hover:text-red-700 text-sm"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>)}
        </div>
      )}
    </div>
  );
};

const UploadProgressPopup = ({ promises = [], onComplete }) => {
  const [completed, setCompleted] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const total = promises.length;

  useEffect(() => {
    if (!promises.length) return;

    const processPromises = async () => {
      await Promise.all(
        promises.map(async (promise) => {
          await promise;
          setCompleted(prev => prev + 1);
        })
      );

      if (onComplete) {
        onComplete();
      }

      setTimeout(() => {
        setIsVisible(false);
      }, 2000);
    };

    processPromises();
  }, [promises, onComplete]);

  if (!isVisible || !promises.length) return null;

  const progress = total ? (completed / total) * 100 : 0;

  return (
    <div className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg p-4 w-64 transform transition-transform duration-200 ease-in-out hover:scale-105 z-50">
      <div className="mb-2 flex justify-between items-center">
        <span className="text-sm font-medium text-gray-700">
          Uploading {completed} of {total} files
        </span>
        <button
          onClick={() => setIsVisible(false)}
          className="text-gray-400 hover:text-gray-600 text-sm"
        >
          ×
        </button>
      </div>

      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div
          className="bg-violet-600 h-2.5 rounded-full transition-all duration-300 ease-out"
          style={{ width: `${progress}%` }}
        />
      </div>

      <div className="mt-1 text-right text-xs text-gray-500">
        {Math.round(progress)}%
      </div>
    </div>
  );
};

function UploadPiece(props) {

  const [instrument, setInstrument] = useState(null)
  const [composerfname, setComposerFName] = useState(null);
  const [composerlname, setComposerLName] = useState(null);
  const [timePeriod, setTimePeriod] = useState(null);
  const [pieceName, setPieceName] = useState(null);
  const [difficulty, setDifficulty] = useState(null);
  const [mode, setMode] = useState('');
  //const [musicxml, setMusicXML] = useState(null);
  const [file, setFile] = useState(null);
  //const [checkbox, setCheckbox] = useState(false)
  const [key, setKey] = useState(null);

  const [uploadPromises, setUploadPromises] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [callback, setCallback] = useState(null);

  const { user } = useContext(AccountContext);

  const uploadSingleFile = async (file, metadata) => {
    try {
      const res = await getPresignedPost(user, 1);
      const file_id = res.file_id;
      console.log('file_id:', file_id);
      const post_url = res.post;
      const data = await file.arrayBuffer();
      await putToS3(post_url, data);
      await uploadPiece(
        user,
        file_id,
        metadata.instrument,
        metadata.composerfname,
        metadata.composerlname,
        metadata.timePeriod,
        metadata.pieceName,
        metadata.difficulty,
        metadata.key
      );
    } catch (error) {
      console.error('Upload failed:', error);
      throw error;
    }
  };


  const bulkPut = async (url, file) => {
    try {
      const data = await file.arrayBuffer();
      await putToS3(url, data);
    } catch (error) {
      console.error('Upload failed:', error);
      throw error;
    }
  };

  const submit = async () => {
    if (mode === 'single' && file) {
      setIsUploading(true);
      const metadata = {
        instrument,
        composerfname,
        composerlname,
        timePeriod,
        pieceName,
        difficulty,
        key
      };

      const promise = uploadSingleFile(file, metadata);
      setUploadPromises([promise]);

      try {
        await promise;
      } catch (error) {
        console.error('Upload failed:', error);
      }
    } else if (mode === 'bulk' && pdfFiles.length > 0) {
      setIsUploading(true);
      const res = await getPresignedPost(user, pdfFiles.length);
      console.log(res);
      const file_ids = res.map((obj) => obj.file_id);
      const file_names = pdfFiles.map((file) => file.name.replace('.pdf', '').replace('|||', ''));
      const promises = pdfFiles.map((file, index) => bulkPut(res[index].post, file));

      setCallback(() => bulkPieceUpload(user, file_names.join("|||"), file_ids.join(",")).then((res) =>
        console.log(res)).catch((err) => console.log(err))
      );
      setUploadPromises(promises);
      try {
        await Promise.all(promises);
      } catch (error) {
        console.error('Bulk upload failed:', error);
      }
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const keySignatures = ['C', 'G', 'D', 'A', 'E', 'B', 'F#', 'C#', 'F', 'Bb', 'Eb', 'Ab', 'Db', 'Gb', 'Cb'];

  return (
    <>
      <Modal title="Upload a piece" button_text="Upload piece" submit={submit} button_view={
        <button type="button" class="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-violet-600 dark:text-violet-300 rounded-lg bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-900 transition-all duration-300">
          Upload a piece
        </button>
      }>
        <ul class="grid w-full gap-6 md:grid-cols-2 mb-4">
          <li onClick={() => setMode('single')}>
            <input type="radio" id="hosting-small" name="hosting" value="hosting-small" class="hidden peer" required />
            <label for="hosting-small" class="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-violet-600 peer-checked:border-violet-600 peer-checked:text-violet-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
              <div class="block">
                <div class="w-full text-lg font-semibold">Single File Upload</div>
                <div class="w-full">For one piece of music</div>
              </div>
            </label>
          </li>
          <li onClick={() => setMode('bulk')}>
            <input type="radio" id="hosting-big" name="hosting" value="hosting-big" class="hidden peer" />
            <label for="hosting-big" class="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-violet-500 peer-checked:border-violet-600 peer-checked:text-violet-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
              <div class="block">
                <div class="w-full text-lg font-semibold">Bulk Upload</div>
                <div class="w-full">For a collection of pieces</div>
              </div>
            </label>
          </li>
        </ul>
        {mode === 'single' ?
          <>
            <div
              class="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
              <div class="w-full">
                <Label label="Instrument (solo)" />
                <select id="instruments"
                  onChange={(e) => setInstrument(e.target.value)}
                  value={instrument}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                  <option selected value="-">Select an instrument</option>
                  <option value="piano">Piano</option>
                </select>
              </div>
              <div class="w-full">
                <Label label="Difficulty score" />
                <select id="time_periods"
                  onChange={(e) => setDifficulty(e.target.value)}
                  value={difficulty}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                  <option selected value="-">Choose a target difficulty</option>
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                </select>
              </div>
            </div>
            <div
              class="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
              <div class="w-full">
                <Label label="Piece name" />
                <input type="text" id="first_name"
                  onChange={(e) => setPieceName(e.target.value)}
                  value={pieceName}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Clair de lune" required />
              </div>
            </div>
            <div
              class="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
              <div class="w-full">
                <Label label="Time period" />
                <select id="countries"
                  onChange={(e) => setTimePeriod(e.target.value)}
                  value={timePeriod}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                  <option selected value="-">Choose a time period</option>
                  <option value="pre_baroque">Renaissance (c. 1400 - 1600)</option>
                  <option value="baroque">Baroque (c. 1600 - 1750)</option>
                  <option value="classical">Classical (c. 1750 - 1830)</option>
                  <option value="early_romantic">Early Romantic (c.1830 - 1860)</option>
                  <option value="late_romantic">Late Romantic (c.1860 - 1920)</option>
                  <option value="modern">20th and 21st century (c. 1920 - present)</option>
                </select>
              </div>
              <div class="w-full">
                <Label label="Key signature" />
                <select id="key"
                  onChange={(e) => setKey(e.target.value)}
                  value={key}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                  <option selected value="">Choose a key</option>
                  {keySignatures.map((key) =>
                    <option value={key} key={key}>{key}</option>
                  )}
                </select>
              </div>
            </div>
            <div
              class="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
              <div class="w-full">
                <Label label="Composer first name" />
                <input type="text" id="first_name"
                  onChange={(e) => setComposerFName(e.target.value)}
                  value={composerfname}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Wolfgang Amadeus" required />
              </div>
              <div class="w-full">
                <Label label="Composer last name" />
                <input type="text" id="first_name"
                  onChange={(e) => setComposerLName(e.target.value)}
                  value={composerlname}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Mozart" required />
              </div>
            </div>
            <div
              class="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
              <div class="w-full">
                <Label label="Piece Upload File" />
                <input accept=".pdf" onChange={handleFileChange} class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-white dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input" type="file" />
                {/* .xml,.musicxml,.mxl, */}
                <p class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">We support PDF upload only right now. We will support XML, MUSICXML, and MXL files soon.</p>
              </div>
            </div>
          </> : mode === 'bulk' ?
            <FileUploader pdfFiles={pdfFiles} setPdfFiles={setPdfFiles} /> : <></>}
      </Modal>
      {isUploading && (
        <UploadProgressPopup
          promises={uploadPromises}
          callback={callback}
          onComplete={() => {
            if (typeof callback === 'function') {
              callback();
            }
            setIsUploading(false);
            setUploadPromises([]);
          }}
        />
      )}
    </>
  )
}

export default UploadPiece