import { base_api_url } from '../config/config.ts';

class API {
  constructor(user) {
    this.auth = user.idToken.jwtToken;
    this.host = base_api_url;
  }


  getRequest(extension) {
    if (!extension.startsWith('/')) { extension = '/' + extension };
    let url = this.host + extension;
    return fetch(url, {
      method: 'get',
      headers: new Headers({
        'Authorization': "Bearer " + this.auth,
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.json())
  }

  postRequest(extension, body) {
    if (!extension.startsWith('/')) { extension = '/' + extension };
    return fetch(this.host + extension, {
      method: 'post',
      headers: new Headers({
        'Authorization': this.auth,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(body)
    })
      .then((response) => response.json())
  }
}

export default API;