import { useState, useContext, useEffect } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { composer_to_pic } from "../../utils/music";
import deleteFromPieceAlbum from "../../api/deleteFromPieceAlbum";
import { AccountContext } from "../../stores/Account";
import PDFViewer from ".//PDFViewer";
import FlagPiece from "./FlagPiece";
import { pdfjs } from 'react-pdf';
import MultiPageViewPDF from "./MultiPageViewPDF";
import file from '../components/dvorak_concerto.pdf';
import { memo } from "react";
import { getInitial } from "../../utils/string";

function UpcomingPiece(props) {

  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const { user } = useContext(AccountContext);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setIsOpen(prevState => !prevState);
  }

  const handleClickActive = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    setIsActive(props.isActive);
  }, [props.isActive]);

  const handleClickOutsideDropdown = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const ref = useClickOutside(handleClickOutsideDropdown);

  const removePieceFromAlbum = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    deleteFromPieceAlbum(user, props.album_id, props.album_piece_id).then((res) => {
      window.location.reload();
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <div onClick={handleClickActive} className={`w-full p-4 bg-white rounded-lg shadow-md flex flex-col items-center justify-between dark:bg-gray-700 ${!isActive && "hover:bg-gray-50 cursor-pointer"}`}>
      <div className="flex flex-row items-end justify-between w-full">
        <div className='flex items-center'>
          <div className="flex-shrink-0">
            {composer_to_pic(props.composer_lname) !== "none" ?
              <img
                src={composer_to_pic(props.composer_lname)}
                className="h-10 w-10 bg-gray-300 rounded-lg object-cover"
              /> : <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-lg dark:bg-gray-600">
                <span class="font-medium text-gray-600 dark:text-gray-300">{getInitial(props.composer_fname) + getInitial(props.composer_lname)}</span>
              </div>
            }
          </div>
          <div className="ml-4 flex-grow">
            <div className="text-sm font-semibold text-black truncate dark:text-white">{props.title}</div>
            <div className="text-sm text-gray-500 truncate dark:text-gray-400">{props.composer_fname} {props.composer_lname}</div>
          </div>
        </div>
        <div class="relative">
          <button onClick={handleClick} class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
              <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
            </svg>
          </button>

          {isOpen && (
            <div ref={ref} class="absolute right-0 mt-2 z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
              <div className="rounded-lg">
                <a href="#" onClick={removePieceFromAlbum} class="block px-4 py-2 text-sm rounded-lg text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-red-600 dark:hover:text-red-600">Remove from list</a>
              </div>
            </div>
          )}
        </div>
      </div>
      {isActive && (
        <>
          {fullScreen && <MultiPageViewPDF file={props.pdf_link} onClose={() => setFullScreen(false)} />}
          <PDFViewer maxHeight={400} src={props.pdf_link}>
            <div className="top-4 right-4 absolute z-10"><svg onClick={() => { setFullScreen(true) }} xmlns="http://www.w3.org/2000/svg" class="cursor-pointer fill-current text-gray-400 h-5 w-5 z-10 hover:text-gray-500" viewBox="0 0 24 24"><path d="m22 5c0-.478-.379-1-1-1h-18c-.62 0-1 .519-1 1v14c0 .621.52 1 1 1h18c.478 0 1-.379 1-1zm-7.5 13.5h-11v-7h11zm1.5 0v-7.5c0-.478-.379-1-1-1h-11.5v-4.5h17v13z" fill-rule="nonzero" /></svg></div>
          </PDFViewer>
          <FlagPiece id={props.id} />
        </>
      )
      }
    </div >
  )
}
export default memo(UpcomingPiece)