import Heading from "../components/Heading";
import UpcomingPiece from "../components/UpcomingPieceObject";
import { useState, useEffect, useContext } from 'react';
import AddNewPiece from "../components/AddNewPiece";
import AddNewPieceObject from "../components/AddNewPieceObject";
import getAlbumData from "../../api/getAlbumData";
import { useParams } from "react-router-dom";
import { AccountContext } from "../../stores/Account";
import deleteAlbum from "../../api/deleteAlbum";
import { daysSinceDate } from "../../utils/datetime";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import reorderAlbumPieces from "../../api/reorderAlbumPieces";


function Album(props) {

  const [activePiece, setActivePiece] = useState(null);

  const { id } = useParams();

  const { user } = useContext(AccountContext);

  const [albumData, setAlbumData] = useState(null);
  const [pieces, setPieces] = useState([]);
  const [mix, setMix] = useState(0);

  useEffect(() => {
    getAlbumData(user, id, mix > 0).then((res) => {
      setAlbumData(res);
      setPieces(res.pieces);
    }).catch((err) => {
      console.log(err);
    })
  }, [mix, id]);

  const handleDeleteAlbum = (e) => {
    e.preventDefault();
    deleteAlbum(user, id).then((res) => {
      window.location.replace('/dashboard/home')
    }).catch((err) => {
      console.log(err);
    })
  }

  const handleNextPiece = () => {
    const currentIndex = pieces.findIndex(piece => piece.album_piece_id === activePiece);
    if (currentIndex < pieces.length - 1) {
      setActivePiece(pieces[currentIndex + 1].album_piece_id);
    }
  };

  const handlePreviousPiece = () => {
    const currentIndex = pieces.findIndex(piece => piece.album_piece_id === activePiece);
    if (currentIndex > 0) {
      setActivePiece(pieces[currentIndex - 1].album_piece_id);
    }
    else if (currentIndex === 0) {
      setActivePiece(null);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedPieces = Array.from(pieces);
    const [movedPiece] = reorderedPieces.splice(result.source.index, 1);
    reorderedPieces.splice(result.destination.index, 0, movedPiece);
    setPieces(reorderedPieces);

    reorderAlbumPieces(user, id, reorderedPieces.map(piece => piece.id));
  };

  return (<div className="box-border overflow-x-hidden shadow-md w-full rounded-lg dark:bg-gray-800 bg-white min-h-full">
    <div className="flex flex-row justify-between w-full flex-nowrap items-end">
      <div className="w-full">
        <div className="flex flex-row flex-wrap justify-between items-center">
          <div className="flex flex-row items-end flex-wrap">
            <Heading title={albumData ? albumData.name : ""} />
            <p className='text-sm leading-none tracking-tight text-gray-700 dark:text-gray-400 p-4 mb-1'>
              Updated {albumData ? (daysSinceDate(albumData.last_updated) > 0 ? daysSinceDate(albumData.last_updated).toString() + "d ago" : "today") : ""}
            </p>
          </div>
          <svg onClick={handleDeleteAlbum} className="m-4 w-8 h-8 hover:text-red-600 text-gray-300 cursor-pointer dark:text-gray-700 dark:hover:text-red-700" aria-hidden="true" fill="none" stroke="currentColor" strokeWidth="2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
        </div>
      </div>
    </div>
    <div className="w-full flex flex-row justify-between items-center px-4 py-2">
      <h4 className='text-xl leading-none tracking-tight text-gray-900 md:text-xl dark:text-white font-semibold'>
        Pieces
      </h4>
      <div className="flex flex-row flex-nowrap gap-2 w-fit h-fit">
        <button type="button" onClick={handlePreviousPiece}
          /*disabled={pieces.findIndex(piece => piece.album_piece_id === activePiece) === 0}*/
          class="w-full flex items-center justify-center px-4 py-1 text-small text-nowrap font-medium text-violet-600 rounded-lg bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" class="fill-current text-violet-600 h-3 w-3 mr-2 mt-0.5 rotate-180" viewBox="0 0 24 24"><path d="M19 12l-18 12v-24l18 12zm4-11h-4v22h4v-22z" /></svg>
          Last piece
        </button>
        <button type="button" onClick={() => { setActivePiece(null); setMix((prev) => prev + 1); }}
          class="w-full flex items-center justify-center px-4 py-1 text-small text-nowrap font-medium text-violet-600 rounded-lg bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700">
          Mix Pieces
        </button>
        <button type="button" onClick={handleNextPiece}
          disabled={pieces.findIndex(piece => piece.album_piece_id === activePiece) === pieces.length - 1}
          class="w-full flex items-center justify-center px-4 py-1 text-small text-nowrap font-medium text-violet-600 rounded-lg bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700">
          Next piece
          <svg xmlns="http://www.w3.org/2000/svg" class="fill-current text-violet-600 h-3 w-3 ml-2 mt-0.5" viewBox="0 0 24 24"><path d="M19 12l-18 12v-24l18 12zm4-11h-4v22h4v-22z" /></svg>
        </button>
      </div>
    </div>
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="pieces">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {pieces.map((piece, index) => (
              <Draggable key={piece.album_piece_id} draggableId={piece.album_piece_id.toString()} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="w-full px-4 py-2">
                    <UpcomingPiece isActive={piece.album_piece_id === activePiece} setActivePiece={setActivePiece} activePiece={activePiece} {...piece} ind={index} album_id={id} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
    {/*{pieces.map(function (object, i) {
      return <div className="w-full px-4 py-2">
        <UpcomingPiece isActive={object.album_piece_id === activePiece} setActivePiece={setActivePiece} activePiece={activePiece} {...object} ind={i} album_id={id} />
      </div>
    })}*/}
    <div className="w-full px-4 py-2">
      <AddNewPiece album_id={id} button_view={
        <AddNewPieceObject />} />
    </div>
  </div>
  );
}

export default Album