import React from 'react';
import { Label } from '../../components/Input';
import useStateParam from '../../hooks/useStateParam';
import { useSearchParams } from 'react-router-dom';

const SightreadingPreferences = () => {
  const [composerSearch, setComposerSearch] = useStateParam('composer', '');
  const [instrument, setInstrument] = useStateParam('instrument', 'piano');
  const [timePeriod, setTimePeriod] = useStateParam('period', 'baroque');
  //const [difficulty, setDifficulty] = useStateParam('difficulty', 'beginner');

  const [searchParams, setSearchParams] = useSearchParams();

  const apply = () => {
    setSearchParams(prev => {
      prev.delete('piece');
      return prev;
    });
    window.location.reload();
  };

  const inputClasses = "w-full p-2 border border-gray-300 rounded-lg focus:ring-violet-600 focus:border-violet-600 bg-white text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500";

  return (
    <div className="w-full max-w-6xl mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div>
          <Label label="Instrument" className="text-gray-700 dark:text-gray-300" />
          <select
            id="instruments"
            onChange={(e) => setInstrument(e.target.value)}
            value={instrument}
            className={inputClasses}
          >
            <option value="-">Select an instrument</option>
            <option value="piano">Piano</option>
            {/* Uncomment these options when available
            <option value="violin">Violin</option>
            <option value="cello">Cello</option>
            */}
          </select>
        </div>
        {/*<div>
          <Label label="Difficulty Score" className="text-gray-700 dark:text-gray-300" />
          <select
            id="difficulty"
            onChange={(e) => setDifficulty(e.target.value)}
            value={difficulty}
            className={inputClasses}
          >
            <option value="-">Choose a target difficulty</option>
            <option value="beginner">Beginner</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
          </select>
        </div>*/}
        <div>
          <Label label="Period" className="text-gray-700 dark:text-gray-300" />
          <select
            id="time_periods"
            onChange={(e) => setTimePeriod(e.target.value)}
            value={timePeriod}
            className={inputClasses}
          >
            <option value="-">Choose a time period</option>
            <option value="pre_baroque">Renaissance (c. 1400-1600)</option>
            <option value="baroque">Baroque (c. 1600-1750)</option>
            <option value="classical">Classical (c. 1750-1830)</option>
            <option value="early_romantic">Early Romantic (c. 1830-1860)</option>
            <option value="late_romantic">Late Romantic (c. 1860-1920)</option>
            <option value="modern">20th and 21st century (c. 1920-present)</option>
          </select>
        </div>
        <div>
          <Label label="Composer" className="text-gray-700 dark:text-gray-300" />
          <input
            type="text"
            id="composer"
            onChange={(e) => setComposerSearch(e.target.value)}
            value={composerSearch}
            className={inputClasses}
            placeholder="Wolfgang Amadeus Mozart"
          />
        </div>
      </div>
      <div className="mt-4">
        <button
          onClick={apply}
          className="w-full sm:w-auto flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-violet-600 hover:bg-violet-700"
        >
          Apply Changes
        </button>
      </div>
    </div>
  );
};

export default SightreadingPreferences;