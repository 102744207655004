export function Error404() {
  return <section class="bg-white dark:bg-gray-900 w-screen h-screen flex justify-center items-center">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-sm text-center">
        <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-violet-600">404</h1>
        <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Something's missing.</p>
        <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, the page you are trying to find may not exist. Please navigate back to the home page or to your Subito dashboard.</p>
        <div className="flex flex-col w-full sm:items-center sm:justify-center space-x-0 sm:flex-row sm:space-x-4">
          <a href="/" class="text-white bg-violet-600 hover:bg-violet-700 focus:ring-4 focus:outline-none focus:ring-violet-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-violet-900 my-2">Back to Home</a>
          <a href="/dashboard/music" class="text-white bg-violet-600 hover:bg-violet-700 focus:ring-4 focus:outline-none focus:ring-violet-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-violet-900 my-2">Back to Dashboard</a>
        </div>
      </div>
    </div>
  </section>
}

export function Error500(props) {
  return <section class="bg-white dark:bg-gray-900 w-screen h-screen flex justify-center items-center">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-sm text-center">
        <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-violet-600">500</h1>
        <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Something went wrong.</p>
        <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, Subito is experiencing technical difficulties at the moment. Please navigate back to the home page or to your Subito dashboard.</p>
        <div className="flex flex-col w-full sm:items-center sm:justify-center space-x-0 sm:flex-row sm:space-x-4">
          <a href="/" class="text-white bg-violet-600 hover:bg-violet-700 focus:ring-4 focus:outline-none focus:ring-violet-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-violet-900 my-2">Back to Home</a>
          <a href="/dashboard/music" class="text-white bg-violet-600 hover:bg-violet-700 focus:ring-4 focus:outline-none focus:ring-violet-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-violet-900 my-2">Back to Dashboard</a>
        </div>
      </div>
    </div>
  </section>
}