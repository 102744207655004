import CreateList from '../components/CreateList';
import ListCard from '../components/ListCard';
import NewListCard from '../components/NewListCard';
import PieceCard from '../components/PieceCard';
import getAlbums from '../../api/getAlbums';
import { useContext, useState, useEffect } from 'react';
import { AccountContext } from '../../stores/Account';
import getRecentPieces from '../../api/getRecentPieces';
import MusicLibraryLoadingView from '../components/MusicLibraryLoadingView';
import Heading from '../components/Heading';

function QuickStart() {

  const [albums, setAlbums] = useState([]);
  const [recentPieces, setRecentPieces] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { user } = useContext(AccountContext);

  useEffect(() => {
    getAlbums(user).then((res) => {
      setAlbums(res.albums);
    }).catch((err) => {
      console.log(err);
    })
  }, []);


  useEffect(() => {
    getRecentPieces(user).then((res) => {
      console.log(res);
      setRecentPieces(res.pieces);
    }).catch((err) => {
      console.log(err);
    })
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [albumsData, recentPiecesData] = await Promise.all([
          getAlbums(user),
          getRecentPieces(user)
        ]);
        setAlbums(albumsData.albums);
        setRecentPieces(recentPiecesData.pieces);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [user]);

  return (
    <div className=''>
      <Heading title='Quickstart' />
      <div className="mt-4 box-border overflow-x-hidden shadow-md w-full rounded-lg dark:bg-gray-700 bg-white min-h-full pb-4 flex-no-wrap">
        {/*<div className="flex flex-row justify-between">*/}
        <div className="flex flex-row justify-start items-center pr-8 flex-nowrap w-full">
          {/* <div className="flex justify-center items-center w-10 h-40 ml-5">
            <TrebleIcon className="w-10 h-20" aria-hidden="true" />
          </div>
          <div>
            <h2 className='text-3xl leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white'>
              Welcome back, <span className="font-semibold">Andrew</span>!
            </h2>
            <h4 className='text-xl pt-2 leading-none tracking-tight text-gray-900 md:text-xl dark:text-white font-semibold'>
              You have practiced <span className="text-violet-600">0 minutes</span> today so far. Keep practicing!
            </h4>
          </div> */}
          {/*</div>
          <div className="p-8">
            <img class="object-cover w-16 h-16 p-1 rounded-full ring-2 ring-violet-600 dark:ring-violet-600"
              src={AndrewPic} />
          </div>*/}
        </div>

        {isLoading ? (
          <div role="status" class="max-w-sm animate-pulse">
            <div class="h-10 bg-gray-200 rounded-full dark:bg-gray-600 w-72 mb-4 m-8"></div>
          </div>
        ) : (
          <h4 className='text-3xl mt-10 leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white pl-8 pb-4 font-semibold'>
            Your Recent Pieces
          </h4>
        )}
        <div className="w-full flex flex-row px-6 gap-2 flex-wrap">
          {isLoading ? (
            <MusicLibraryLoadingView />
          ) : recentPieces.length > 0 ? (
            recentPieces.map((piece, i) => (
              <PieceCard key={piece} index={i} piece={piece} />
            ))
          ) : (
            <div className="text-center w-full text-gray-600 dark:text-gray-400 mt-10">No Recent Pieces Found</div>
          )}
        </div>
        {isLoading ? (
          <div role="status" class="max-w-sm animate-pulse">
            <div class="mt-14 h-10 bg-gray-200 rounded-full dark:bg-gray-600 w-36 mb-4 m-8"></div>
          </div>
        ) : (
          <h4 className='text-3xl leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white pl-8 pb-4 font-semibold mt-12'>
            Your Lists
          </h4>
        )}
        {isLoading ? (
          <div className='flex flex-row px-6 flex-wrap gap-4 mb-4'>
            <div className="animate-pulse flex flex-col">
              <div className="flex flex-col justify-center items-center rounded-lg bg-gray-100 dark:bg-gray-800 w-full sm:w-56 h-72 mb-4 p-4">
                <div className="w-16 h-16 bg-gray-200 dark:bg-gray-600 rounded-full"></div>
                <div className="h-6 bg-gray-200 dark:bg-gray-600 rounded-full w-32 mt-4"></div>
              </div>
            </div>
            <div className="flex flex-row gap-4">
              {Array.from({ length: 2 }, (_, index) => (
                <div key={index} className="animate-pulse flex flex-col">
                  <div className="flex flex-col justify-center items-center rounded-lg bg-gray-100 dark:bg-gray-800 w-full sm:w-64 h-72 mb-4 p-4">
                    <div className="h-40 bg-gray-200 dark:bg-gray-600 rounded-lg w-full"></div>
                    <div className="h-4 bg-gray-200 dark:bg-gray-600 rounded-full w-28 mt-4"></div>
                    <div className="h-3 bg-gray-200 dark:bg-gray-600 rounded-full w-32 mt-2"></div>
                    <div className='mt-4 flex justify-between w-full gap-2'>
                      <div className="h-8 bg-gray-200 dark:bg-gray-600 rounded-lg w-full"></div>
                      <div className="h-8 bg-gray-200 dark:bg-gray-600 rounded-lg w-full"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex flex-row px-6 flex-wrap gap-4 mb-4">
            <CreateList button_view={
              <NewListCard />
            } />
            {albums.map((album, index) => <ListCard key={index} {...album} />)}
          </div>
        )}

        {/*<div class="w-full sm:w-1/2 pl-8">
          <form class="flex items-center">
            <label for="simple-search" class="sr-only">Search</label>
            <div class="relative w-full">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                </svg>
              </div>
              <input
                type="text" id="simple-search" class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:border-violet-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-violet-600 dark:focus:ring-violet-600 dark:focus:border-violet-500" placeholder="Search" required="" />
            </div>
          </form>
        </div>*/}



        {/* <div class="max-w-min bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700" style="width: 150px; height: 400px;">
          <a href="#">
            <img class="rounded-t-lg" src={FillerImage} alt="filler image" />
          </a>
          <div class="p-5">
            <a href="#">
              <h5 class="mb-2 text-xl font-bold text-wrap tracking-tight text-black dark:text-white">Annees de pelerinage 2 1 Gondoliera</h5>
            </a>
            <p class="mb-3 font-normal text-gray-500 dark:text-gray-200">Franz Listz</p>
            <a href="#" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Read more
              <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
              </svg>
            </a>
          </div>
        </div> */}
      </div >
    </div>
  )
}

export default QuickStart;