function AddNewPieceObject(props) {

  return (
    <div className="w-full p-4 bg-white rounded-lg shadow-md flex items-center justify-between dark:bg-gray-700 hover:bg-gray-50">
      <div className="flex-shrink-0 bg-gray-100 dark:bg-gray-700 h-12 w-12 rounded-lg flex items-center justify-center">
        <svg className="w-8 h-8 text-violet-600" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.75v14.5M19.25 12H4.75"></path>
        </svg>
      </div>
      <div className="ml-4 flex-grow">
        <div className="text-sm test-black font-semibold truncate dark:text-white">Add New Piece</div>
      </div>
    </div>
  )
}
export default AddNewPieceObject