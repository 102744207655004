import { useState, useEffect, useContext } from 'react'

import { AccountContext } from '../../stores/Account'
import AdvancedSearch from '../components/AdvancedSearch'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import useStateParam from '../../hooks/useStateParam'
import { useSearchParams } from 'react-router-dom'
import getMyMusic from '../../api/getMyMusic'
import searchMusic from '../../api/searchMusic'
import UploadPiece from '../components/UploadPiece'
import Heading from '../components/Heading'
import PieceCard from '../components/PieceCard'
import CreateList from '../components/CreateList'
import getAlbums from '../../api/getAlbums'
//import KeyboardShortcuts from '../components/KeyboardShortcuts'
import Pagination from '../components/Pagination'
import MusicLibraryLoadingView from '../components/MusicLibraryLoadingView'
import NoResults from '../components/NoResults'

let toggles = [{
  label: "All Music",
  id: "all",
  icon: <svg class="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
  </svg>
},
{
  label: "Recently Viewed",
  id: 'viewed',
  icon: <svg class="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
  </svg>
},
{
  label: "Starred Pieces",
  id: 'starred',
  icon: <svg class="w-4 h-4 me-2 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
  </svg>
},
{
  label: "My Uploads",
  id: 'my',
  icon: null
}]

function Music() {

  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([])

  const [firstRender, setFirstRender] = useState(true);

  const resultsPerPage = 10
  const [albums, setAlbums] = useState([]);

  const [totalResults, setTotalResults] = useState(0);

  const { user } = useContext(AccountContext);

  const [searchWord, setSearchWord] = useStateParam('search', undefined);

  const [toggle, setToggle] = useStateParam('pieces', ["all", "viewed", "starred", "my", ...albums.map(album => album.id)], "all");
  const [page, setPage] = useStateParam('page', "int", 1);

  const search = (offset) => {
    //(user, query, type, order_by, instrument, difficulty, time_period, key_signature, composer, offset) => {
    return searchMusic(user, searchWord, toggle, searchParams.get('shuffle') === 'true' ? 'shuffle' : null,
      searchParams.get('instrument'), searchParams.get('difficulty'), searchParams.get('period'), undefined,
      searchParams.get('composer'), offset)
  }

  useEffect(() => {
    getAlbums(user).then((res) => {
      setAlbums(res.albums);
    }).catch((err) => {
      console.log(err);
    })
  }, []);

  //could use useDidMountEffect here instead of firstRender to prevent running this on first load,
  //but this is less buggy for now...
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchWord !== undefined && searchWord !== null) {
        setIsLoading(true);
        setData([])
        setPage(1)
        search(0).then((data) => {
          setData(data.pieces);
          setTotalResults(data.count)
          setIsLoading(false);
          setFirstRender(false)
        }).catch((err) => {
          setIsLoading(false);
        })
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  useEffect(() => {
    setData([])
    setPage(1)
    setIsLoading(true);
    setTotalResults(0);
    search(0).then((data) => {
      setData(data.pieces);
      setTotalResults(data.count)
      setIsLoading(false);
      setFirstRender(false)
    }).catch((err) => {
      setIsLoading(false);
    })
  }, [toggle])

  function onPageChange(p) {
    if (!firstRender) {
      setPage(p)
      setData([]);
      search((p - 1) * resultsPerPage).then((more_pieces) => {
        setData(more_pieces.pieces);
      }).catch((err) => console.log(err));
    }
  }

  const handleFormInput = (e) => {
    e.preventDefault();
  }

  const { width } = useWindowDimensions();

  return (
    <div className='w-full overflow-hidden'>
      <Heading title="Music Library" />
      <div className="mt-4 box-border overflow-x-hidden shadow-md w-full rounded-lg dark:bg-gray-700 bg-white">
        <div className='flex flex-col lg:flex-row sm:justify-between pt-2 bg-violet-50 dark:bg-gray-800'>
          <div class="mx-4">
            <ul class="flex flex-no-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 overflow-x-scroll no-scrollbar">
              {width > 520 ?
                <>
                  {toggles.map((element) =>
                    <li class="me-2" onClick={() => setToggle(element.id)}>
                      <a href="#" className={`inline-flex items-center justify-center p-4 border-b-2 group rounded-t-lg active text-nowrap
                                  ${toggle === element.id ? "text-violet-600 border-violet-600" :
                          "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}`} aria-current="page">
                        {element.icon ?? ''}{element.label}
                      </a>
                    </li>)}
                  {albums.map((element) =>
                    <li class="me-2" onClick={() => setToggle(element.id)}>
                      <a href="#" className={`inline-flex items-center justify-center p-4 border-b-2 group rounded-t-lg active text-nowrap
                                                ${toggle === element.id ? "text-violet-600 border-violet-600" :
                          "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}`} aria-current="page">
                        {element.name}
                      </a>
                    </li>)}
                </>
                : <select id="playlists" onChange={(e) => setToggle(e.target.value)}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                  <option value="all" selected>All Pieces</option>
                  <option value="viewed">Recently Viewed</option>
                  <option value="starred">Starred Pieces</option>
                </select>
              }
            </ul>
          </div>

          <div className="flex flex-row w-full sm:w-auto space-x-4 p-4">
            {/* <div className="w-full sm:w-auto">
              <AdvancedSearch />
            </div> */}
            <div className="w-full sm:w-auto">
              <UploadPiece />
            </div>
            <div className="w-full sm:w-auto">
              <CreateList button_view={
                <button type="button" class="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-violet-600 dark:text-violet-300 rounded-lg bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-900 transition-all duration-300">
                  <svg xmlns="http://www.w3.org/2000/svg" class="fill-current text-violet-600 dark:text-violet-300 h-3 w-3 mr-2" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" /></svg>
                  Create a list
                </button>
              } />
            </div>
          </div>
        </div>
        <div className='flex flex-row items-center'>
          <div class="flex flex-col w-full flex-wrap items-center justify-between p-4 space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
            <div class="w-full">
              <form class="flex items-center" onSubmit={handleFormInput}>
                <label for="simple-search" class="sr-only">Search</label>
                <div class="relative w-full">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <input onChange={(e) => setSearchWord(e.target.value)}
                    value={searchWord}
                    type="text" id="simple-search" class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:border-violet-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-violet-600 dark:focus:ring-violet-600 dark:focus:border-violet-500" placeholder="Search" required="" />
                </div>
              </form>
            </div>
          </div>
          <div className="w-full sm:w-auto pr-4">
            <AdvancedSearch />
          </div>
        </div>

        {isLoading ? (
          <div className="flex flex-row flex-wrap gap-1 gap-y-4 overflow-y-scroll no-scrollbar">
            {Array.from({ length: 10 }, (_, index) => (
              <MusicLibraryLoadingView />
            ))}
          </div>
        ) : data.length > 0 ? (
          <div className='flex'>
            <div className="flex flex-row justify-center md:justify-start lg:ml-4 flex-wrap gap-1 gap-y-4 overflow-y-scroll no-scrollbar">
              {data.map((piece, index) => (
                <PieceCard key={index} piece={piece} />
              ))}
            </div>
          </div>
        ) : (
          <NoResults />
        )}

        <div className="p-4">
          <Pagination
            totalResults={totalResults}
            resultsPerPage={resultsPerPage}
            onChange={onPageChange}
            label="Table navigation"
          />
        </div>
      </div>
    </div>
  )
}

export default Music
