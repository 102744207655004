import {
  FormsIcon
} from '../../assets/icons';

function Plan() {
  return (
    <div className="w-full bg-white dark:bg-gray-800 shadow-md sm:rounded-lg p-10 mt-3 mb-3">
      <div className="flex flex-start mb-2">
        <img className="mt-1 h-8" src="/SubitoLogoNoText.png" alt="No Text Logo" />
        <h2 className="ml-3 text-2xl font-bold text-violet-800 dark:text-white mb-4">Your Current Subito Plan</h2>
      </div>
      <dl class="w-full text-gray-700">
        <div class="flex flex-col pb-2 space-y-2">
          <h2 className="text-lg font-bold text-gray-900 dark:text-white">Current Subscription</h2>
          <span className='dark:text-gray-400'>Free Beta Plan ($15.00 / month)</span>
        </div>
        <div class="flex flex-col py-2 space-y-2">
          <h2 className="text-lg font-bold text-gray-900 dark:text-white">Features</h2>
          <li class="flex items-center space-x-3 rtl:space-x-reverse">
            <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
            </svg>
            <span className='dark:text-gray-400'>Music library with AI-powered search</span>
          </li>
          <li class="flex items-center space-x-3 rtl:space-x-reverse">
            <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
            </svg>
            <span className='dark:text-gray-400'>Quick and easy sight-reading practice tool</span>
          </li>
          <li class="flex items-center space-x-3 rtl:space-x-reverse">
            <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
            </svg>
            <span className='dark:text-gray-400'>Dynamic customized practice exercises</span>
          </li>
        </div>
        {/* <div class="flex flex-col pt-2 space-y-2">
          <h2 className="text-lg font-bold text-gray-900">Support Package</h2>
          <span>Email: learnsubito@gmail.com</span>
        </div> */}
        <div class="flex pt-4">
          <dd class="text-gray-800 text-sm font-bold">Next payment of $36 occurs on August 13, 2020.</dd>
        </div>
      </dl>
      <div className="w-full flex flex-row space-x-4 justify-start mt-3">
        <button className="inline-flex items-center px-6 py-2.5 text-sm font-semibold text-center text-white hover:text-violet-600 bg-violet-600 rounded-lg hover:bg-white hover:shadow-sm focus:outline-none focus:ring-violet-300 transition-all duration-500">
          <FormsIcon className="w-5 h-5 mr-1.5"/>
          Upgrade Plan
        </button>
        <button className="py-2.5 px-6 ms-2 text-sm font-semibold text-gray-500 hover:text-black focus:outline-none bg-gray-50 rounded-lg hover:bg-gray-100 hover:shadow-sm focus:z-10 focus:ring-gray-100 transition-all duration-300">
          Cancel Subscription
        </button>
      </div>
      {/* <small className="text-gray-500 text-xs">*We are yet to release any upgrade plan options.</small> */}
    </div>
  )
}

export default Plan