export const isEqualsJson = (obj1: Record<string, any>, obj2: Record<string, any>): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  return keys1.length === keys2.length && keys1.every(key => obj1[key] === obj2[key]);
}

export const isEmpty = (obj: any): boolean => {
  try {
    if (obj !== undefined && obj !== null) {
      return Object.keys(obj).length === 0;
    }
  } catch (err) {
    return false;
  }
  return true;
}

export const isKey = (obj: any, key: string): boolean => {
  if (obj === undefined || obj === null) { return false; }
  return Object.keys(obj).includes(key);
}

export const getSafeValue = (obj: any, key: string, type: string, dec: number = 2, def?: any): any => {

  const default_mapping: { [key: string]: any } = {
    'int': 0,
    'float': 0.0,
    'string': "",
    'object': {},
    'list': [],
    //'datetime': undefined,
    'boolean': false,
    'unknown': undefined
  }

  try {
    if (isKey(obj, key)) {
      if (!obj[key]) return def !== undefined ? def : default_mapping[type];
      if (type === 'int') return parseInt(obj[key]);
      if (type === 'float') return parseFloat(parseFloat(obj[key]).toFixed(dec));
      if (type === 'boolean') return (getSafeValue(obj, key, 'int') === 1);
      // if (type === 'datetime') return Datetime.reformateDatetime(getSafeValue(obj, key, "string"));

      return obj[key];
    }
  } catch (err) { }

  return def !== undefined ? def : default_mapping[type];
}

export const fetchObjectFromList = (l: any[], key: string, value: any): any => {
  const p = l.filter((obj) => (getSafeValue(obj, key, "unknown") === value));
  return p.length > 0 ? p[0] : getSafeValue({}, 'fake', 'object');
}

export const try_value = <T>(lmbda: () => T): T | null => {
  try {
    return lmbda();
  } catch {
    return null;
  }
}