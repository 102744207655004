import { daysSinceDate } from "../../utils/datetime";
import deleteAlbum from "../../api/deleteAlbum";
import { useContext } from "react";
import { AccountContext } from "../../stores/Account";
import ConcertHall from "../../assets/stock/concerthall.png"
import Piano from "../../assets/stock/piano.png"
import SheetMusic from "../../assets/stock/sheetmusic.png"

const ListCard = (props) => {

  const { user } = useContext(AccountContext);

  const handleDeleteAlbum = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    deleteAlbum(user, props.id).then((res) => {
      window.location.reload();
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <a href={"/dashboard/music/album/" + props.id} className="w-full sm:w-64 h-72 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 flex flex-col items-center justify-center hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200">
      <img className="w-full h-40 rounded-lg" src={props.index % 3 == 0 ? Piano : props.index % 3 == 1 ? SheetMusic : ConcertHall} />
      <div className="mt-2 text-lg font-semibold text-gray-900 dark:text-white">{props.name}</div>
      <p className="text-sm text-gray-500 dark:text-gray-400">Last updated {daysSinceDate(props.last_updated) > 0 ? daysSinceDate(props.last_updated).toString() + "d ago" : "today"}</p>
      <div className="mt-2 flex justify-between w-full">
        <a href={"/dashboard/music/album/" + props.id} className="flex items-center justify-center w-1/2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-lg font-semibold text-xs p-2 mr-2 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200">
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" strokeWidth="2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 3l14 9-14 9V3z"></path>
          </svg>
          <span className="mb-0.5">
            Play
          </span>
        </a>
        <button onClick={handleDeleteAlbum} className="flex items-center justify-center w-1/2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:text-red-600 dark:hover:text-red-400 font-semibold text-xs rounded-lg p-2 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200">
          <svg className="w-4 h-4 mr-1" aria-hidden="true" fill="none" stroke="currentColor" strokeWidth="2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
          <span className="mb-0.5">
            Delete
          </span>
        </button>
      </div>
    </a>
  );
};

export default ListCard;