import React, { useState, useRef, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';

const ComingSoonOverlay = () => {
    return (
        <div className="z-50 rounded-lg absolute inset-0 flex items-center justify-center bg-white dark:bg-black dark:bg-opacity-50 dark:backdrop-blur-sm bg-opacity-50 backdrop-blur-sm h-full w-full">
            <div className="text-gray-900 dark:text-white text-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto mb-2 text-gray-900 dark:text-white h-10 w-10" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M17 9.761v-4.761c0-2.761-2.238-5-5-5-2.763 0-5 2.239-5 5v4.761c-1.827 1.466-3 3.714-3 6.239 0 4.418 3.582 8 8 8s8-3.582 8-8c0-2.525-1.173-4.773-3-6.239zm-8-4.761c0-1.654 1.346-3 3-3s3 1.346 3 3v3.587c-.927-.376-1.938-.587-3-.587s-2.073.211-3 .587v-3.587zm4 11.723v2.277h-2v-2.277c-.596-.347-1-.984-1-1.723 0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723z" /></svg>
                <p className="text-2xl font-bold">Coming Soon</p>
            </div>
        </div>
    );
};

const WeeklyPractice = () => {
    const navigate = useNavigate();

    const goToHomepage = () => {
        navigate('/dashboard/music');
    };

    const options = {
        chart: {
            height: "100%",
            type: "area",
            fontFamily: "Inter, sans-serif",
            dropShadow: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            enabled: true,
            x: {
                show: false,
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                opacityFrom: 0.55,
                opacityTo: 0,
                shade: "#6C48EF",
                gradientToColors: ["#6C48EF"],
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 6,
        },
        grid: {
            show: false,
            strokeDashArray: 4,
            padding: {
                left: 2,
                right: 2,
                top: 0
            },
        },
        series: [
            {
                name: "Hours of Pratice",
                data: [13.4, 9.2, 10.1, 15.4, 12.3, 6.7],
                color: "#6C48EF",
            },
        ],
        xaxis: {
            categories: ['01 February', '02 February', '03 February', '04 February', '05 February', '06 February', '07 February'],
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            show: false,
        },
    }

    return (
        <div className="w-full bg-white dark:bg-gray-800 shadow-md sm:rounded-lg p-10 mt-3 mb-4">
            <div className="flex flex-start mb-3">
                <h2 className="text-2xl font-bold text-black mb-4 dark:text-white">Weekly Practice</h2>
            </div>
            <div className="w-full bg-white rounded-lg shadow dark:bg-gray-700 p-4 md:p-6 relative">
                <ComingSoonOverlay />
                <div className="flex justify-between">
                    <div>
                        <h5 className="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">67.1 hrs</h5>
                        <p className="text-base font-normal text-gray-500 dark:text-gray-400">Practice this week</p>
                    </div>
                    <div className="flex items-center px-2.5 py-0.5 text-base font-semibold text-green-500 dark:text-green-500 text-center">
                        12%
                        <svg className="w-3 h-3 ml-1 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13V1m0 0L1 5m4-4 4 4" />
                        </svg>
                        than last week
                    </div>
                </div>
                <ReactApexChart options={options} series={options.series} type="area" height={350} />
                <button onClick={goToHomepage} type="button" class="focus:outline-none text-white font-semibold bg-violet-600 hover:bg-violet-700 hover:shadow-lg focus:ring-4 focus:ring-purple-300 rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-violet-600 dark:hover:bg-violet-700 dark:focus:ring-purple-900 transition-all duration-300">Go Practice</button>
            </div>
        </div>
    )
}

export default WeeklyPractice;
