import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import useStateParam from '../../hooks/useStateParam';
import { AccountContext } from '../../stores/Account';
import searchMusic from '../../api/searchMusic';
import Heading from '../components/Heading';
import SightreadingPreferences from '../components/SightreadingPreferences';
import Piece from './Piece';
import NoResults from '../components/NoResults';

function Sightreading() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const { user } = useContext(AccountContext);
  const [page, setPage] = useStateParam('piece', "int", 1);
  const [offset, setOffset] = useStateParam('offset', "int", 0);
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(true);

  const [isLoading, setIsLoading] = useState(true);

  const handleKeyPress = useCallback((event) => {
    if (event.key === "Tab") {
      setPage((prev) => prev + 1);
    }
  }, []);

  const search = (offset) => {
    //(user, query, type, order_by, instrument, difficulty, time_period, key_signature, composer, offset) => {
    return searchMusic(user, '', 'all', 'shuffle',
      searchParams.get('instrument'), searchParams.get('difficulty'), searchParams.get('period'), undefined,
      searchParams.get('composer'), offset)
  }

  useEffect(() => {
    if (page <= offset || page > offset + 25) {
      setOffset(Math.floor((page - 1) / 25) * 25);
    }
  }, [page]);

  useEffect(() => {
    document.removeEventListener('keydown', handleKeyPress);
    setIsLoading(true);
    setData([]);
    if (!page) setPage(1);
    search(offset).then((res) => {
      setData(res.pieces);
      setIsLoading(false);
      document.addEventListener('keydown', handleKeyPress);
    }).catch((err) => {
      setIsLoading(false);
      console.log(err);
    });
  }, [offset]);

  useEffect(() => {
    setTotalResults(data ? data.length : 0);
  }, [data]);

  return (
    <div className="box-border overflow-x-hidden">
      <Heading title='Sightreading Practice' />
      <div className="w-full box-border mb-2 mt-4">
        <div className="bg-white shadow-md dark:bg-gray-800 rounded-lg">
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <div className="flex flex-row pt-2 px-4 pb-2 items-center">
                <h4 onClick={() => setIsPreferencesOpen(!isPreferencesOpen)} className='text-lg leading-none cursor-pointer tracking-tight text-gray-900 md:text-lg dark:text-white font-semibold mr-2 p-2 hover:text-gray-600 dark:hover:text-gray-200 transition-all duration-150'>
                  Set sightreading preferences
                </h4>
                <button
                  onClick={() => setIsPreferencesOpen(!isPreferencesOpen)}
                  className="pt-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  {isPreferencesOpen ?
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-black dark:text-white" fill="currentColor" viewBox="0 0 24 24"><path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" /></svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-black dark:text-white" fill="currentColor" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" /></svg>}
                </button>
              </div>
            </div>
            <div className="flex space-x-2 p-4">
              {page > 1 && (
                <button
                  type="button"
                  onClick={() => setPage((prev) => prev - 1)}
                  className="px-4 py-2 text-sm font-medium rounded-lg bg-gray-50 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-900 dark:text-white transition-all duration-150"
                >
                  Last piece
                </button>
              )}
              <button
                type="button"
                onClick={() => setPage((prev) => prev + 1)}
                className="px-4 py-2 text-sm font-medium text-white rounded-lg bg-violet-600 hover:bg-violet-700"
              >
                Next piece
              </button>
            </div>
          </div>
          {isPreferencesOpen && <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700 mx-6" />}
          <div className="relative">
            <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isPreferencesOpen ? 'max-h-96' : 'max-h-0'}`}>
              <div className="p-4">
                <SightreadingPreferences />
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isLoading &&
        <>
          {page && (page - 1 - offset) < data.length && data[page - 1 - offset] ? (
            <Piece id={data[page - 1 - offset].id} piece={data[page - 1 - offset]} />
          ) : (
            <NoResults />
          )}
        </>
      }
    </div>
  );
}

export default Sightreading;
