import { useState, useContext, useEffect } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { composer_to_pic, format_period } from "../../utils/music";
import { AccountContext } from "../../stores/Account";
import PDFViewer from "../components/PDFViewer";
import FlagPiece from "../components/FlagPiece";
import { pdfjs } from 'react-pdf';
import MultiPageViewPDF from "../components/MultiPageViewPDF";
import file from '../components/dvorak_concerto.pdf';
import getPiece from "../../api/getPiece";
import starPiece from "../../api/starPiece";
import unstarPiece from "../../api/unstarPiece";
import { useParams } from "react-router-dom";
import PieceType from "../../types/piece";
import Heading from "../components/Heading";
import MultiPageViewFlat from "../components/MultiPageViewFlat";
import { getInitial } from "../../utils/string";
import viewPiece from "../../api/viewPiece";
import AddToList from "../components/AddToList";

const StarIcon = ({ filled, onClick }) => (
  <svg
    style={{ pointerEvents: 'auto' }}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill={filled ? "currentColor" : "none"}
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={filled ? "text-yellow-300" : "text-gray-400"}
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
  </svg>
);

function Piece(props) {
  const { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [fullScreenPDF, setFullScreenPDF] = useState(false);
  const [fullScreenFlat, setFullScreenFlat] = useState(false);
  const [piece, setPiece] = useState(props.piece ?? new PieceType({}));
  const [starred, setStarred] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const { user } = useContext(AccountContext);

  useEffect(() => {
    if (props.piece) {
      setPiece(props.piece);
    }
  }, [props.piece])

  useEffect(() => {
    if (!props.piece) {
      getPiece(user, props.id ?? id).then((res) => {
        setPiece(res);
        if (res.starred === 1) {
          setStarred(true);
        }
      }).catch((err) => {
        console.log(err);
      })
    }
  }, [id, props.id, props.piece])

  useEffect(() => {
    viewPiece(user, props.id ?? id);
  }, [])

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setIsOpen(prevState => !prevState);
  }

  const handleClickOutsideDropdown = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const ref = useClickOutside(handleClickOutsideDropdown, isDisabled);

  const star = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (!starred) {
      starPiece(user, piece.id).then(() => {
        setStarred(true);
      }).catch((err) => {
        console.log(err);
      })
    } else {
      unstarPiece(user, piece.id).then(() => {
        setStarred(false);
      }).catch((err) => {
        console.log(err);
      })
    }
  }

  return (
    <div className="w-full bg-white rounded-lg shadow-md flex flex-col dark:bg-gray-700 pb-4">
      <div className="flex flex-row justify-between items-center">
        {!piece.title ? (
          <div role="status" class="max-w-sm animate-pulse">
            <div class="h-10 bg-gray-200 rounded-lg dark:bg-gray-600 w-96 m-4"></div>
          </div>
        ) : (
          <Heading title={piece.title} />
        )}
        <div className="p-4">
          <StarIcon onClick={star} filled={starred} />
        </div>
      </div>
      <div className="flex flex-row items-end justify-between w-full px-4">
        <div className='flex items-center'>
          <div className="flex-shrink-0">
            {composer_to_pic(piece.composer_lname) !== "none" ?
              <img
                src={composer_to_pic(piece.composer_lname)}
                className="h-10 w-10 bg-gray-300 rounded-lg object-cover"
              /> : <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-lg dark:bg-gray-600">
                <span class="font-medium text-gray-600 dark:text-gray-300">{getInitial(piece.composer_fname) + getInitial(piece.composer_lname)}</span>
              </div>
            }
          </div>
          <div className="ml-4 flex-grow">
            <div className="text-sm font-semibold text-black truncate dark:text-white">{piece.composer_fname} {piece.composer_lname}</div>
            <div className="text-sm text-gray-500 truncate dark:text-gray-400">{format_period(piece.time_period)}</div>
          </div>
        </div>
        <div className="relative">
          <button onClick={handleClick} className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
              <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
            </svg>
          </button>

          {isOpen && (
            <div ref={ref} className="absolute right-0 mt-2 z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
              <ul class="text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconHorizontalButton">
                <li>
                  <a onClick={star} class="rounded-t-lg block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">{starred ? "Unstar Piece" : "Star Piece"}</a>
                </li>
                <AddToList setDisabled={setDisabled} piece_id={piece.id} button_view={
                  <li>
                    <a href="#" class="rounded-b-lg block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">Add to List</a>
                  </li>} />
              </ul>
            </div>
          )}
        </div>
      </div>
      {piece.type === 'pdf' ?
        <div className="px-4">
          {/*{fullScreenFlat && <MultiPageViewFlat title={piece.title} composer={piece.composer_fname + ' ' + piece.composer_lname} onClose={() => setFullScreenFlat(false)} />}*/}
          {fullScreenPDF && <MultiPageViewPDF file={piece.pdf_link} onClose={() => setFullScreenPDF(false)} />}
          <PDFViewer height="auto" src={piece.pdf_link}>
            <div className="top-4 right-4 absolute z-10">
              <svg onClick={() => { setFullScreenPDF(true) }} xmlns="http://www.w3.org/2000/svg" className="cursor-pointer fill-current text-gray-500 h-5 w-5 z-10 hover:text-gray-600 transition-all duration-300" viewBox="0 0 24 24">
                <path d="m22 5c0-.478-.379-1-1-1h-18c-.62 0-1 .519-1 1v14c0 .621.52 1 1 1h18c.478 0 1-.379 1-1zm-7.5 13.5h-11v-7h11zm1.5 0v-7.5c0-.478-.379-1-1-1h-11.5v-4.5h17v13z" fillRule="nonzero" />
              </svg>
            </div>
            {/*<div className="top-3 right-11 absolute z-10">
              <button onClick={() => { setFullScreenFlat(true) }} className="bg-gradient-to-r from-violet-600 to-blue-500 hover:shadow-md text-white font-extrabold rounded-md text-xs w-12 transition-all duration-300">
                Flat
              </button>
            </div>*/}
          </PDFViewer>
          <FlagPiece id={piece.id} />
        </div> : <></>}
    </div>
  )
}

export default Piece