import React, { useState, useEffect } from 'react';

const PasswordStrengthChecker = (props) => {

  let password = props.password;

  const [isStrongPassword, setStrongPassword] = useState(false);


  useEffect(() => {
    setStrongPassword(password && /[0-9]/.test(password) && password.length >= 8 && /[A-Z]/.test(password) && /[a-z]/.test(password) && /[@$!%*?&]/.test(password));
  }, [password]);

  if (password) {
    return (
      <div className="">
        <div className="">
          <div className="flex items-center w-full -mt-2 mb-2">
            <div className={`h-2.5 rounded-full w-full mr-2 ${password.length >= 8 ? 'bg-green-500' : 'bg-gray-200 dark:bg-gray-700'}`}></div>
            <div className={`h-2.5 rounded-full w-full mr-2 ${/[A-Z]/.test(password) ? 'bg-green-500' : 'bg-gray-200 dark:bg-gray-700'}`}></div>
            <div className={`h-2.5 rounded-full w-full mr-2 ${/[a-z]/.test(password) ? 'bg-green-500' : 'bg-gray-200 dark:bg-gray-700'}`}></div>
            <div className={`h-2.5 rounded-full w-full mr-2 ${/[0-9]/.test(password) ? 'bg-green-500' : 'bg-gray-200 dark:bg-gray-700'}`}></div>
            <div className={`h-2.5 rounded-full w-full mr-2 ${/[@$!%*?&]/.test(password) ? 'bg-green-500' : 'bg-gray-200 dark:bg-gray-700'}`}></div>
          </div>
          <span className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white ">
            Your password must contain: 
          </span>
          <ul>
            <li className="flex items-center mb-2">
              <svg className={`w-3 h-3 fill-current mr-2 shrink-0 ${password.length >= 8 ? 'text-green-500' : 'text-gray-100 dark:text-gray-700'}`} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <label className="block text-sm font-medium text-gray-900 dark:text-white">
                Minimum 8 characters
              </label>
            </li>
            <li className="flex items-center mb-2">
              <svg className={`w-3 h-3 fill-current mr-2 shrink-0 ${/[A-Z]/.test(password) ? 'text-green-500' : 'text-gray-100 dark:text-gray-700'}`} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <label className="block text-sm font-medium text-gray-900 dark:text-white">
                At least one uppercase letter
              </label>
            </li>
            <li className="flex items-center mb-2">
              <svg className={`w-3 h-3 fill-current mr-2 shrink-0 ${/[a-z]/.test(password) ? 'text-green-500' : 'text-gray-100 dark:text-gray-700'}`} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <label className="block text-sm font-medium text-gray-900 dark:text-white">
                At least one lowercase letter
              </label>
            </li>
            <li className="flex items-center mb-2">
              <svg className={`w-3 h-3 fill-current mr-2 shrink-0 ${/[0-9]/.test(password) ? 'text-green-500' : 'text-gray-100 dark:text-gray-700'}`} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <label className="block text-sm font-medium text-gray-900 dark:text-white">
                At least one number
              </label>
            </li>
            <li className="flex items-center mb-2">
              <svg className={`w-3 h-3 fill-current mr-2 shrink-0 ${/[@$!%*?&]/.test(password) ? 'text-green-500' : 'text-gray-100 dark:text-gray-700'}`} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <label className="block text-sm font-medium text-gray-900 dark:text-white">
                At least one symbol (@$!%*?&)
              </label>
            </li>
          </ul>
        </div>
        <span id="errorMessage" className={`block text-sm font-semibold ${isStrongPassword ? 'text-green-500' : 'text-red-500'}`}>
          {isStrongPassword ? 'Strong password' : 'Weak password'}
        </span>
      </div>
    );
  }
};

export default PasswordStrengthChecker;