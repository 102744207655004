import API from ".";

const uploadPiece = (user, piece_id, instrument, composerfname, composerlname, timePeriod, pieceName, difficulty, key_signature) => {

  let obj = {
    "piece_id": piece_id ?? '',
    "instrument": instrument ?? '',
    "composer_fname": composerfname ?? '',
    "composer_lname": composerlname ?? '',
    "period": timePeriod ?? '',
    "title": pieceName ?? '',
    "difficulty": difficulty ?? '',
    "key_signature": key_signature ?? ''
  }

  return new API(user).postRequest('/uploadPiece', obj);
}

export default uploadPiece;