import React, { useState, useEffect, useContext } from 'react';
import useStateParam from '../../hooks/useStateParam';
import { AccountContext } from '../../stores/Account';
import searchMusic from '../../api/searchMusic';

function SearchableDropdown(props) {

  const MAX_RECORDS = 25;

  const [searchSimilar, setSearchSimilar] = useState(false);

  const [offset, setOffset] = useState(0);
  const [results, setResults] = useState([]);

  const [lazyLoad, setLazyLoad] = useState(false);

  const [searchQuery, setSearchQuery] = useState(null);

  const [matchKey, setMatchKey] = useStateParam('matchkey', "bool", new Boolean(false))

  const [match, setMatch] = useStateParam('matchid');

  const { user } = useContext(AccountContext);

  let show = match && match !== ""

  useEffect(() => {
    if (show) {
      setSearchSimilar(true);
    }
  }, [match])

  function handleSearchSimilar(e) {
    setSearchSimilar(e.target.checked)
    if (!e.target.checked) {
      setSearchQuery(null);
      setMatch(null);
      setMatchKey(false);
    }
  }

  function get_name() {
    if (match && match !== "" && match.split("?matchname=").length === 2) {
      return match.split("?matchname=")[1]
    }
  }

  const handleScroll = (e) => {
    let dist = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
    if (dist < 50 && lazyLoad) {
      setOffset((prev) => prev + MAX_RECORDS);
    }
  }

  useEffect(() => {
    setLazyLoad(false);
    searchMusic(user, searchQuery, undefined, undefined, undefined, undefined, undefined, undefined, undefined, offset).then((data) => {
      if (data.pieces.length >= MAX_RECORDS) setLazyLoad(true);
      setResults((prev) => [...prev, ...data.pieces]);
    }).catch((err) => {
      console.log(err)
    })
  }, [offset])

  useEffect(() => {
    setLazyLoad(false);
    searchMusic(user, searchQuery).then((data) => {
      if (data.length >= MAX_RECORDS) setLazyLoad(true);
      setResults(data.pieces);
    }).catch((err) => {
      console.log(err)
    })
  }, [searchQuery])

  return (
    <>
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id="remember"
            aria-describedby="remember"
            name="remember"
            type="checkbox"
            onChange={handleSearchSimilar}
            checked={searchSimilar}
            className="w-4 h-4 border-gray-300 rounded text-violet-600 bg-gray-50 focus:ring-3 focus:ring-violet-300 dark:focus:ring-violet-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
            required
          />
        </div>
        <div className="ml-3 text-sm mb-3">
          <label htmlFor="remember" className="font-medium text-gray-900 dark:text-white">
            Search for similar pieces by content with our custom AI tools.
          </label>
        </div>
      </div>
      {searchSimilar &&
        <div className="relative">
          {!(show) ?
            <><div>
              <label for="simple-search" class="sr-only">Search</label>
              <div class="relative w-full">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                </div>
                <input type="text"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  id="simple-search" class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-violet-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-violet-600 dark:focus:ring-violet-600 dark:focus:border-violet-500" placeholder="Search" required="" />
              </div>
            </div>
              {searchQuery &&
                <ul className="max-h-48 overflow-y-scroll no-scrollbar w-full bg-white dark:bg-gray-800 shadow-md rounded-b-lg" onScroll={handleScroll}>
                  {results.map(item => (
                    <li key={item.id} class="flex hover:bg-gray-50 dark:hover:bg-gray-700 p-2 z-50 border-t border-gray-100" onClick={() => {
                      setMatch(item.id + "?matchname=" + item.title)
                    }}>
                      <label for="helper-checkbox-3" class="font-medium text-gray-900 dark:text-gray-300">
                        <div>{item.title}, {item.composer_fname} {item.composer_lname}</div>
                      </label>
                    </li>
                  ))}
                </ul>}</> :
            <div>
              <label for="simple-search" class="sr-only">Selected Piece</label>
              <div class="relative w-full">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                </div>
                <input type="text"
                  onChange={(e) => {
                    setMatch(null);
                  }}
                  value={get_name(match)}
                  id="simple-search" class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-violet-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-violet-600 dark:focus:ring-violet-600 dark:focus:border-violet-500" placeholder="Search" required="" />
              </div>
            </div>
          }
        </div>}
      {show &&
        <div className="flex items-start mt-3">
          <div className="flex items-center h-5">
            <input
              id="remember"
              aria-describedby="remember"
              name="remember"
              type="checkbox"
              onChange={(e) => setMatchKey(e.target.checked)}
              checked={matchKey.valueOf()}
              className="w-4 h-4 border-gray-300 rounded text-violet-600 bg-gray-50 focus:ring-3 focus:ring-violet-300 dark:focus:ring-violet-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
              required
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="remember" className="font-medium text-gray-900 dark:text-white">
              Require key signature match between similar pieces.
            </label>
          </div>
        </div>
      }
    </>
  );
};

export default SearchableDropdown