function SidebarShuffleView(props) {
  return (
    <a href="/dashboard/music?page=1&shuffle=true" className="flex cursor-pointer flex-row items-center justify-between rounded-md border-2 border-violet-600 hover:shadow-sm hover:bg-gray-50 dark:hover:shadow-sm dark:hover:bg-gray-700 transition-colors duration-150 p-2">
      <svg className="w-6 h-6 stroke-current text-violet-600 ml-3 mr-1" fill="none" strokeWidth="3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" d="M5 3l14 9-14 9V3z"></path>
      </svg>
      <div className="text-sm text-violet-600 text-center font-semibold" >Click to start shuffling through library</div>
    </a>
  )
}
export default SidebarShuffleView