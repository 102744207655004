export function formatString(theString: string): string {
  if (!theString) {
    return "";
  } else {
    return theString;
  }
}

export function capitalize(string: string): string {
  if (!string || string === undefined || string === null) { return ""; }

  string = string.replace("_", " ");
  const words = string.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(" ");
}

export function isEmptyString(string: string): boolean {
  return string === undefined || string === null || !(string.length > 0) ||
    string === "" || !string.replace(/\s/g, '').length;
}

export function checkLowercase(str: string): boolean {
  for (var i = 0; i < str.length; i++) {
    if (str.charAt(i) == str.charAt(i).toLowerCase() && str.charAt(i).match(/[a-z]/i)) {
      return true;
    }
  }
  return false;
}

export function checkUppercase(str: string): boolean {
  for (var i = 0; i < str.length; i++) {
    if (str.charAt(i) == str.charAt(i).toUpperCase() && str.charAt(i).match(/[a-z]/i)) {
      return true;
    }
  }
  return false;
}

export function getInitial(str: string): string {
  if (str && str.length > 0) {
    return str.charAt(0).toUpperCase();
  }
  return "";
}

export function lowercase(str: string): string {
  if (str && str !== null) {
    return str.toLowerCase();
  }
  return "";
}
