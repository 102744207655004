import React from 'react';

const PrevIcon = (props) => (
  <svg {...props} aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
    <path
      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
);

const NextIcon = (props) => (
  <svg {...props} aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
    <path
      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
);

const PageTurner = ({ activePage, setActivePage, totalPages }) => {
  const handlePreviousClick = () => {
    setActivePage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextClick = () => {
    setActivePage((prev) => Math.min(prev + 1, totalPages));
  };

  return (
    <div className="flex items-center justify-between space-x-4">
      <button
        className="p-2 border rounded-full disabled:opacity-50 hover:bg-gray-50"
        onClick={handlePreviousClick}
        disabled={activePage === 1}
        aria-label="Previous Page"
      >
        <PrevIcon className="h-4 w-4" />
      </button>
      <span className="text-sm font-semibold">
        Page {activePage} of {totalPages}
      </span>
      <button
        className="p-2 border rounded-full disabled:opacity-50 hover:bg-gray-50"
        onClick={handleNextClick}
        disabled={activePage === totalPages}
        aria-label="Next Page"
      >
        <NextIcon className="h-4 w-4" />
      </button>
    </div>
  );
};

export default PageTurner;