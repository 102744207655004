import React, { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import Embed from 'flat-embed';
import { FileLoadingIcon } from '../../assets/icons';
import { SystemContext } from '../../stores/System';

function FlatEmbed() {
  const containerRef = useRef(null);

  //const { darkMode } = useContext(SystemContext);
  //const [png, setPNG] = useState(FileLoadingIcon);

  useEffect(() => {
    if (containerRef.current) {
      const embed = new Embed(containerRef.current, {
        width: '100%',
        height: '300',
        score: "66d50904c46d7576d9f42e2f",
        lazy: true,
        off: true,
        embedParams: {
          layout: 'page',
          zoom: 'auto',
          themeScoreBackground: 'transparent',
          branding: false,
          drawOnlyFirstPage: true,
          noHeader: true,
          controlsDisplay: false,
          appId: '667c53d0e181f889c06e5f62',
          sharingKey: "7c62ddc6faef05d1ac3b4412b14327f69cbed39eaf6eaffac527ba797448f5f49a89e1445153e1465fbdfaf67ad644eac40b321e3a7cde9a8621b4abb93d1b13"
        }
      });
      /*embed.getPNG({
        result: 'dataURL',
        layout: 'page',
        dpi: 150,
      })
        .then(function (png) {
          setPNG(png);
        }).catch((e) => {
          console.log('Catching error:', e);
        })*/
    }
  });

  return <>
    <div ref={containerRef} className="cursor-pointer select-none"></div>
  </>;
}

export default FlatEmbed;