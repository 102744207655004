import React from 'react';
import './styles/index.css';
import App from './app/App';
import { render } from 'react-dom';
import { hydrateRoot } from 'react-dom/client';
import registerServiceWorker from './app/registerServiceWorker';

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  render(<App />, rootElement);
}

registerServiceWorker();
