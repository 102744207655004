export const errorMessageMap: object = {
  "CodeMismatchException": "Incorrect code. Please try again.",
  "PasswordAttemptsExceeded": "Too many login attempts. Please wait a few seconds and try again.",
  "LimitExceededException": "Too many attempts. Please wait a while and try again.",
  "NotAuthorizedException": "Incorrect email or password. Please try again.",
  "TermsAndPrivacy": "You must agree to Subito's Terms and Conditions and Privacy Policy to create an account.",
  "MatchingPasswords": "Passwords do not match. Please try again.",
  "InvalidParameterException": "Your password must have minimum eight characters, one uppercase letter, one lowercase letter, one symbol, and one digit.",
  "InvalidPasswordException": "Your password must have minimum eight characters, one uppercase letter, one lowercase letter, one symbol, and one digit.",
  "UsernameExistsException": "An account with this email address already exists. Please login or use a different email.",
  "CodeDeliveryFailureException": "Please enter a valid email address.",
  "General": "Something went wrong. Please try again.",
  "UserNotConfirmedException": "You did not finish the onboarding flow. You may signup again with the same email address tomorrow.",
  "EnableSoftwareTokenMFAException": "Incorrect code. Please try again.",
  "ExpiredCodeException": "Expired code. Please resend the token.",
  "PasswordConform": "Password does not conform to requirements. Please try again."
}

export function getAmplifyErrorMessage(err: { message: string }): string {
  let message: string = err.message;
  if (message.endsWith('.')) {
    return message;
  } else {
    return message + ".";
  }
  /*
  if(isKey(errorMessageMap, err.code) && err.code !== "NotAuthorizedException"){
      return errorMessageMap[err.code]
  }else if(err.code === "NotAuthorizedException"){
      if(err.message === "Password attempts exceeded"){
          return errorMessageMap["PasswordAttemptsExceeded"]
      }else{
          return errorMessageMap["NotAuthorizedException"]
      }
  }else{
      return errorMessageMap["General"]
  }*/
}