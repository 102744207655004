import React from 'react';

const ProfileLoadingView = () => {
  return (
    <div className="w-full mb-4 bg-white shadow-md dark:bg-gray-800 sm:rounded-lg p-10 mt-3 animate-pulse">
      <div className="flex flex-col items-center">
        <div className="h-40 w-40 bg-gray-300 rounded-full dark:bg-gray-700"></div>
        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 my-4"></div>
        <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-4"></div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="h-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          <div className="h-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-10 bg-gray-200 rounded-full dark:bg-gray-700 w-full mt-4"></div>
      </div>
    </div>
  );
}

export default ProfileLoadingView;
