import { useState, useContext } from 'react';
import { Document, Page } from 'react-pdf';
import starPiece from "../../api/starPiece";
import unstarPiece from "../../api/unstarPiece";
import { AccountContext } from "../../stores/Account";
import LoadingView from "./LoadingView";
import useClickOutside from "../../hooks/useClickOutside";
import viewPiece from '../../api/viewPiece';
import AddToList from './AddToList';

const StarIcon = ({ filled, onClick }) => (
  <svg
    style={{ pointerEvents: 'auto' }}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill={filled ? "currentColor" : "none"}
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={filled ? "text-yellow-300" : "text-gray-400"}
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
  </svg>
);

function PieceCard(props) {

  const { piece } = props;
  const [starred, setStarred] = useState(props.piece.starred === 1);

  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setIsOpen(prevState => !prevState);
  }


  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const ref = useClickOutside(handleClickOutside, isDisabled);

  const { user } = useContext(AccountContext);

  const star = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (!starred) {
      starPiece(user, piece.id).then(() => {
        setStarred(true);
      }).catch((err) => {
        console.log(err);
      })
    } else {
      unstarPiece(user, piece.id).then(() => {
        setStarred(false);
      }).catch((err) => {
        console.log(err);
      })
    }
  }

  const view = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    viewPiece(user, piece.id)
  }

  return (
    <div onClick={() => window.location.href = "/dashboard/music/piece/" + piece.id}
      className="box-border hover:shadow-lg dark:hover:shadow-lg hover:bg-gray-50 dark:hover:bg-gray-900 rounded-b-lg cursor-pointer transition-all duration-300"
      style={{ width: '220px', height: '400px' }}
    >
      <div style={{ width: '220px', height: '240px', pointerEvents: 'none' }} className="relative flex justify-center items-center overflow-y-hidden"> {/*flex justify-center items-center  */}
        {piece.type === 'pdf' ?
          <Document file={piece.pdf_link} className="w-full no-scrollbar overflow-x-hidden overflow-y-hidden" onLoadError={console.error}
            loading={LoadingView}>
            <Page pageNumber={1} scale={0.3} />
          </Document> : <LoadingView />}
        <div className="absolute bottom-4 right-4">
          <p className='z-10 mb-3 text-wrap text-center font-normal text-xs bg-violet-600 text-white p-1 w-20 rounded-lg'>
            Piano Solo
          </p>
        </div>
        <div className="absolute top-4 right-4 cursor-pointer z-10 gap-2 flex flex-row">
          <StarIcon filled={starred} onClick={star} />
        </div>
        <div className="absolute top-4 left-4 cursor-pointer z-10 bg-red-100">

        </div>
        {/*<FileLoadingIcon className="fill-current w-20 h-20 text-gray-400" />*/}
        {/*<FlatEmbed />*/}
      </div>
      <div className="p-2 px-4 flex flex-col">
        <div className="flex flex-row items-start justify-between">
          <h5 className="mb-2 text-md font-bold text-wrap tracking-tight text-black dark:text-white gap-x-2">
            {piece.title}
          </h5>
          <div className="relative">
            <div onClick={handleClick} class="inline-flex items-center text-sm font-medium text-center text-gray-900 rounded-lg dark:text-white pt-1">
              <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
              </svg>
            </div>
            {isOpen && (
              <div ref={ref} class="absolute right-0 mt-2 z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                <ul class="text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconHorizontalButton">
                  <AddToList setDisabled={setIsDisabled} piece_id={piece.id} button_view={
                    <li>
                      <span class="rounded-t-lg block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Add to List</span>
                    </li>} />
                  <li>
                    <span onClick={star} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{starred ? "Unstar Piece" : "Star Piece"}</span>
                  </li>
                  <li>
                    <span onClick={view} class="rounded-b-lg block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Mark as Viewed</span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <p className="mb-3 text-wrap text-sm font-normal text-gray-500 dark:text-gray-200">
          {piece.composer_fname} {piece.composer_lname}
        </p>
      </div>
    </div >
  )
}

export default PieceCard