import React, { useContext, useEffect, useRef } from 'react';
import { CSSTransition as ReactCSSTransition } from 'react-transition-group';

const transitionContext = React.createContext({
  parent: {},
});

function useIsInitialRender() {
  const isInitialRender = useRef(true);
  useEffect(() => {
    isInitialRender.current = false;
  }, []);
  return isInitialRender.current;
}

const CSSTransition = function CSSTransition({
  show,
  enter = '',
  enterFrom = '',
  enterTo = '',
  leave = '',
  leaveFrom = '',
  leaveTo = '',
  appear,
  children,
}) {
  const enterClasses = enter.split(' ').filter(s => s.length);
  const enterFromClasses = enterFrom.split(' ').filter(s => s.length);
  const enterToClasses = enterTo.split(' ').filter(s => s.length);
  const leaveClasses = leave.split(' ').filter(s => s.length);
  const leaveFromClasses = leaveFrom.split(' ').filter(s => s.length);
  const leaveToClasses = leaveTo.split(' ').filter(s => s.length);

  function addClasses(node, classes) {
    classes.length && node.classList.add(...classes);
  }

  function removeClasses(node, classes) {
    classes.length && node.classList.remove(...classes);
  }

  return (
    <ReactCSSTransition
      appear={appear}
      unmountOnExit
      in={show}
      addEndListener={(node, done) => {
        node.addEventListener('transitionend', done, false);
      }}
      onEnter={node => {
        addClasses(node, [...enterClasses, ...enterFromClasses]);
      }}
      onEntering={node => {
        removeClasses(node, enterFromClasses);
        addClasses(node, enterToClasses);
      }}
      onEntered={node => {
        removeClasses(node, [...enterToClasses, ...enterClasses]);
      }}
      onExit={node => {
        addClasses(node, [...leaveClasses, ...leaveFromClasses]);
      }}
      onExiting={node => {
        removeClasses(node, leaveFromClasses);
        addClasses(node, leaveToClasses);
      }}
      onExited={node => {
        removeClasses(node, [...leaveToClasses, ...leaveClasses]);
      }}
    >
      {children}
    </ReactCSSTransition>
  );
};

const Transition = function Transition({ show, appear, ...rest }) {
  const { parent } = useContext(transitionContext);
  const isInitialRender = useIsInitialRender();
  const isChild = show === undefined;

  if (isChild) {
    return <CSSTransition appear={parent.appear} show={parent.show} {...rest} />;
  } else {
    return (
      <transitionContext.Provider
        value={{
          parent: {
            show,
            isInitialRender,
            appear,
          },
        }}
      >
        <CSSTransition appear={appear} show={show} {...rest} />
      </transitionContext.Provider>
    );
  }
};

export default Transition;
