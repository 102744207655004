import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { OpenSheetMusicDisplay as OSMD } from 'opensheetmusicdisplay';
import { TableFooter } from '../../components/Table';
import Pagination from './Pagination';
import { SystemContext } from '../../stores/System';

const OpenSheetMusicDisplay = ({ music, measures, autoResize = true, hideTitle = true, hidePagination = false }) => {
  const divRef = useRef(null);

  const { darkMode } = useContext(SystemContext);

  const [page, setPage] = useState(1);

  const MEASURES_PER_PAGE = 20

  let osmd;

  const handleKeyPress = useCallback((event) => {
    event.preventDefault();
    if (event.key === "Enter") {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const setupOsmd = () => {
      const options = {
        autoResize,
        'drawTitle': hideTitle ? false : page === 1,
        'drawComposer': hideTitle ? false : page === 1,
        'drawPartNames': false,
        'defaultColorMusic': darkMode ? "#ffffff" : "#000000",
        'pageBackgroundColor': "#12345600",
        'drawFromMeasureNumber': (page - 1) * MEASURES_PER_PAGE,
        'drawUpToMeasureNumber': page * MEASURES_PER_PAGE,
        'stretchLastSystemLine': true
      };
      osmd = new OSMD(divRef.current, options);
      osmd.load(music).then(() => osmd.render());
    };

    setupOsmd();

    document.addEventListener('keydown', handleKeyPress);

    const handleResize = () => {
      osmd.render();
    };

    window.scrollTo(0, 0);

    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
      window.removeEventListener('resize', handleResize);
    };
  }, [autoResize, page, darkMode]);

  useEffect(() => {
    if (osmd) {
      osmd.load(music).then(() => osmd.render());
    }
  }, [music]);

  return <div className="flex flex-col">
    <div ref={divRef} />
    {hidePagination ? <></> :
      <TableFooter>
        <Pagination
          totalResults={Math.ceil(measures / MEASURES_PER_PAGE)}
          music_pages={true}
          resultsPerPage={1}
          activePage={page}
          onChange={(p) => setPage(p)}
          label="Music pagination"
        />
      </TableFooter>}
  </div>;
};

export default OpenSheetMusicDisplay;