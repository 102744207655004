import { useState } from "react";

function PasswordInput(props) {

  const [show, setShow] = useState(false);

  return (
    <div className="relative">
      <input
        type={show ? "text" : "password"}
        name="password"
        onChange={(e) => props.setValue(e.target.value)}
        value={props.value}
        id="password"
        placeholder="••••••••"
        className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
        required
      />
      <button type="button" class="absolute top-0 end-0 flex h-full justify-center items-center px-4 rounded-e-md" onClick={() => setShow((prev) => !prev)}>
        <svg class="flex-shrink-0 size-4 text-gray-400 dark:text-neutral-600" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          {show ?
            <>
              <path class="" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
              <path class="n" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
              <path class="" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
              <line class="" x1="2" x2="22" y1="2" y2="22"></line>
            </> :
            <>
              <path class="" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
              <circle class="" cx="12" cy="12" r="3"></circle>
            </>
          }
        </svg>
      </button>
    </div>
  )
}

export default PasswordInput