import React from 'react';

function Footer() {
  return (


    <footer class="bg-white dark:bg-gray-900 m-4 mt-36">
      <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div class="sm:flex sm:items-center sm:justify-between">
          <a href="/" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
            <img className="h-12" src="/SubitoLogo.png" />
          </a>
          <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <a href="/signin" class="hover:underline me-4 md:me-6">Sign in</a>
            </li>
            <li>
              <a href="/signup" class="hover:underline me-4 md:me-6">Sign up</a>
            </li>
            <li>
              <a href="/" class="hover:underline me-4 md:me-6">Home</a>
            </li>
          </ul>
        </div>
        <hr class="my-6 border-gray-100 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© {(new Date().getFullYear())} Subito. All Rights Reserved.</span>
      </div>
    </footer>


  );
}

export default Footer;
