import React, { useState, useEffect } from "react";
import Plan from "../components/Plan";
import UserInfo from "../components/UserInfo";
import OrderHistory from '../components/OrderHistory';
import Heading from "../components/Heading";
import CardDetails from '../components/CardDetails';
import WeeklyPractice from "../components/WeeklyPractice";
import ProfileLoadingView from "../components/ProfileLoadingView";

function Profile() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full overflow-hidden">
      <Heading title='Profile' />
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <div className="w-full md:w-1/2 flex">
          {isLoading ? <ProfileLoadingView /> : <UserInfo />}
        </div>
        <div className="w-full md:w-70 flex relative">
          <WeeklyPractice />
        </div>
      </div>
      
      {/* <OrderHistory /> */}
      {/* <div className="flex flex-col mt-1 md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <div className="w-full md:w-1/2 flex">
          <Plan />
        </div>
        <div className="w-full md:w-1/2 flex">
          <CardDetails />
        </div>
      </div> */}
    </div>
  );
}

export default Profile;
