import { lowercase } from "../utils/string"

export const Label = (props) => {
  const { label } = props;

  return (
    <label for={lowercase(label).replace(" ", "_")}
      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {label}
    </label>);
}

export const Select = (props) => {
  const { id, setValue, value } = props;

  return (
    <select
      id={id}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 
    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
      <option selected value="-">{props.default}</option>
      {props.options.map((item) =>
        <option value={item.value}>{item.label}</option>
      )}
    </select>
  );
}

export const Input = (props) => {
  const { id, onChange, value } = props;

  return (
    <></>
  )
}

export const SelectTimePeriod = (props) => {
  const { id, onChange, value } = props;

  return (
    <></>
  );

}