import API from ".";

const getAlbums = (user) => {
  let url = '/getAlbums';

  return new API(user).getRequest(url).then(json => {
    return json;
  });
}

export default getAlbums;