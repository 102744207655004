import SearchableDropdown from './SearchableDropdown';
import Modal from '../../components/Modal';
import useStateParam from '../../hooks/useStateParam';
import { Label } from '../../components/Input';

function AdvancedSearch(props) {
  const [composerSearch, setComposerSearch] = useStateParam('composer', undefined)

  const [instrument, setInstrument] = useStateParam('instrument', ["piano"]) //"cello", "violin"

  const [timePeriod, setTimePeriod] = useStateParam('period', ["baroque", "classical", "early_romantic", "late_romantic", "modern"])

  //const [difficulty, setDifficulty] = useStateParam('difficulty', ["beginner", "intermediate", "advanced"])

  return (
    <Modal title={props.other_title ? props.other_title : "Advanced search"} button_text="Apply filters" submit={() => window.location.reload()} button_view={
      <button type="button" class="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-violet-600 hover:bg-violet-700 transition-all duration-300">
        {props.other_title ? props.other_title : "Advanced\u00A0search"}
      </button>
    }>
      <div
        class="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
        <div class="w-full">
          <Label label="Instrument" />
          <select id="instruments"
            onChange={(e) => setInstrument(e.target.value)}
            value={instrument}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
            <option selected value="-">Select an instrument</option>
            <option value="piano">Piano</option>
            {/*<option value="violin">Violin</option>
            <option value="cello">Cello</option>*/}
          </select>
        </div>
        <div class="w-full">
          <Label label="Period" />
          <select id="countries"
            onChange={(e) => setTimePeriod(e.target.value)}
            value={timePeriod}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
            <option selected value="-">Choose a time period</option>
            <option value="renaissance">Renaissance (c. 1600- 1400)</option>
            <option value="baroque">Baroque (c. 1600-c. 1750)</option>
            <option value="classical">Classical (c. 1750-c. 1830)</option>
            <option value="early_romantic">Early Romantic (c.1830-1860)</option>
            <option value="late_romantic">Late Romantic (c.1860-c.1920)</option>
            <option value="modern">20th and 21st century (c. 1920-present)</option>
          </select>
        </div>
        {/*<div class="w-full">
          <Label label="Difficulty Score" />
          <select id="time_periods"
            onChange={(e) => setDifficulty(e.target.value)}
            value={difficulty}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
            <option selected value="-">Choose a target difficulty</option>
            <option value="beginner">Beginner</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
          </select>
        </div>*/}
      </div>
      <div
        class="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
        <div class="w-full">
          <Label label="Composer" />
          <input type="text" id="first_name"
            onChange={(e) => setComposerSearch(e.target.value)}
            value={composerSearch}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            placeholder="Wolfgang Amadeus Mozart" required />
        </div>
      </div>
      {/*<div
        class="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
        <div class="w-full">
          <Label label="Composer" />
          <input type="text" id="first_name"
            onChange={(e) => setComposerSearch(e.target.value)}
            value={composerSearch}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            placeholder="Wolfgang Amadeus Mozart" required />
        </div>
        <div class="w-full">
          <Label label="Period" />
          <select id="countries"
            onChange={(e) => setTimePeriod(e.target.value)}
            value={timePeriod}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
            <option selected value="-">Choose a time period</option>
            <option value="pre_baroque">Renaissance (c. 1600- 1400)</option>
            <option value="baroque">Baroque (c. 1600-c. 1750)</option>
            <option value="classical">Classical (c. 1750-c. 1830)</option>
            <option value="early_romantic">Early Romantic (c.1830-1860)</option>
            <option value="late_romantic">Late Romantic (c.1860-c.1920)</option>
            <option value="modern">20th and 21st century (c. 1920-present)</option>
          </select>
        </div>
      </div>
      <div className="w-full">
        <Label label="Piece Type" />
        <div className="flex flex-row w-full">
          <div class="flex items-center w-full">
            <input checked id="bordered-radio-2" type="radio" value="" name="bordered-radio" class="w-4 h-4 text-violet-600 bg-gray-100 border-gray-300 focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label for="bordered-radio-2" class="w-full ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">All Pieces</label>
          </div>
          <div class="flex items-center w-full">
            <input checked id="bordered-radio-2" type="radio" value="" name="bordered-radio" class="w-4 h-4 text-violet-600 bg-gray-100 border-gray-300 focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label for="bordered-radio-2" class="w-full ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">MusicXML Only</label>
          </div>
          <div class="flex items-center w-full">
            <input checked id="bordered-radio-2" type="radio" value="" name="bordered-radio" class="w-4 h-4 text-violet-600 bg-gray-100 border-gray-300 focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label for="bordered-radio-2" class="w-full ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">PDF Only</label>
          </div>
        </div>
      </div>*/}
      {/*<SearchableDropdown instrument={instrument} />*/}
    </Modal>
  )
}

export default AdvancedSearch