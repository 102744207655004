import API from ".";

const createAlbum = (user, name) => {

  let obj = {
    "piece_id": "",
    "album_id": "",
    "name": name
  }

  return new API(user).postRequest('/createAlbum', obj);
}

export default createAlbum;