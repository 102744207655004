import { Outlet, useLocation } from "react-router-dom";
import { SidebarProvider, SidebarContext } from "./components/Sidebar";
import { AccountContext } from "../stores/Account";
import React, { useState, useContext, useEffect, useMemo } from "react";
import { isKey } from "../utils/json";
import { Navigate } from "react-router-dom";
import Sidebar from './components/Sidebar'
import Header from "./components/Header";
import useWindowDimensions from "../hooks/useWindowDimensions";

function DashboardProvider() {
  const { isSidebarOpen, closeSidebar, isExpanded } = useContext(SidebarContext);

  const { width } = useWindowDimensions();

  let location = useLocation()

  useEffect(() => {
    closeSidebar();
  }, [location]);

  const contentStyle = useMemo(() => {
    if (width >= 768 && width <= 1024) {
      return { marginLeft: '64px' } //isExpanded ? '256px' : '64px' };
    }
    return { marginLeft: '0px' };
  }, [isExpanded, width]);

  return (
    <>
      <Header />
      <div className="flex flex-row w-screen h-screen overflow-x-hidden bg-gray-50 dark:bg-gray-900">
        <Sidebar />
        <div className="p-4 overflow-y-scroll overflow-x-hidden no-scrollbar w-full" style={contentStyle}>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default function Dashboard(props) {
  const { user, setUser, getSession } = useContext(AccountContext)

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSession().then((user) => {
      setUser(user);
      console.log(user.idToken.jwtToken);
      setIsLoading(false);
    }).catch((err) => {
      setUser(null);
      setIsLoading(false);
    });
  }, []);

  if (!isLoading) {
    if (user && isKey(user, "idToken") && isKey(user.idToken, "jwtToken")) {
      //console.log(user.idToken.jwtToken);
      return <SidebarProvider>
        <DashboardProvider>
          <Outlet />
        </DashboardProvider>
      </SidebarProvider>
    } else {
      return <Navigate to="/signin?failed=true" />;
    }
  }
}
