import Mozart from "../assets/composers/mozart.jpg";
import Bach from "../assets/composers/bach.jpg";
import Chopin from "../assets/composers/chopin.jpg";
import Liszt from "../assets/composers/liszt.jpg";
import Rachmaninoff from "../assets/composers/rachmaninoff.jpg";
import Haydn from "../assets/composers/haydn.jpg";
import Beethoven from "../assets/composers/beethoveen.jpg";
import { capitalize } from "./string";
import { createImageFromInitials } from "../components/LetterPicture";
import { primary_color } from "../config/config";
import { getInitial } from "./string";

export const keyenc_to_string = (key_encoding: number): string => {
  const map: { [key: number]: string } = {
    0: 'C',
    1: 'F',
    2: 'B-flat',
    3: 'E-flat',
    4: 'A-flat',
    5: 'D-flat',
    6: 'G-flat',
    7: 'C-flat',
    8: 'G',
    9: 'D',
    10: 'A',
    11: 'E',
    12: 'B',
    13: 'F-sharp',
    14: 'C-sharp'
  };
  return map[key_encoding];
};

export const composer_to_pic = (name: string): string | undefined => {
  name = name.toLowerCase();
  switch (name) {
    case "mozart": return Mozart;
    case "chopin": return Chopin;
    case "liszt": return Liszt;
    case "bach": return Bach;
    case "haydn": return Haydn;
    case "rachmaninoff": return Rachmaninoff;
    case "beethoven": return Beethoven;
    default: return "none" //createImageFromInitials(500, getInitial(name), primary_color);
  }
};


export const year_to_period = (year: number): string => {
  if (year < 1600) return "Renaissance";
  else if (year < 1750) return "Baroque";
  else if (year < 1830) return "Classical";
  else if (year < 1860) return "Early Romantic";
  else if (year < 1920) return "Late Romantic";
  else if (year > 1920) return "Modern";
  return "Unknown";
};

export const format_period = (period: string): string => {
  if (period === "pre_baroque") return "Renaissance";
  return capitalize(period.replace("_", " "));
};
