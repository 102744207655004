interface UserInfoInput {
  first_name?: string;
  last_name?: string;
  email?: string;
  instrument?: string;
  joined_date?: string;
  profile_pic?: string;
}

class UserInfo {
  public readonly first_name: string;
  public readonly last_name: string;
  public readonly email: string;
  public readonly instrument: string;
  public readonly date_joined: string;
  public readonly profile_pic: string;

  constructor(input: UserInfoInput) {
    this.first_name = input.first_name ?? ''; // Default to empty string if not provided
    this.last_name = input.last_name ?? '';
    this.email = input.email ?? '';
    this.instrument = input.instrument ?? '';
    this.date_joined = input.joined_date ?? '';
    this.profile_pic = input.profile_pic ?? '';
  }
}

export default UserInfo;
