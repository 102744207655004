import API from ".";

const flagPiece = (user, id, message) => {

  let obj = {
    "piece_id": id,
    "message": message
  }

  return new API(user).postRequest('/flagPiece', obj);
}

export default flagPiece;