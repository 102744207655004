import API from ".";

const deleteFromPieceAlbum = (user, album, piece) => {

  let obj = {
    "piece_id": piece,
    "album_id": album,
    "name": ""
  }

  return new API(user).postRequest('/deleteFromPieceAlbum', obj);
}

export default deleteFromPieceAlbum;