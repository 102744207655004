import API from ".";

const unstarPiece = (user, id) => {

  let obj = {
    "piece_id": id,
  }

  return new API(user).postRequest('/unstarPiece', obj);
}

export default unstarPiece;