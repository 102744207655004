const putToS3 = (url, data) => {
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Length': new Blob([data]).size,
      'Content-Type': 'application/pdf'
    },
    body: data
  });
};

export default putToS3