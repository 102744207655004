import flagPiece from '../../api/flagPiece';
import { AccountContext } from '../../stores/Account';
import Modal from '../../components/Modal';
import { useContext, useState } from 'react';

function FlagPiece(props) {

  const [message, setMessage] = useState(null)
  const [status, setStatus] = useState('neutral')

  const { user } = useContext(AccountContext);

  const submit = () => {
    flagPiece(user, props.id, message).then(() => {
      setStatus('success')
      setMessage(null);
      window.location.reload()
    }).catch((err) => {
      setStatus('failed')
      console.log(err);
      window.location.reload()
    })
  }

  return (
    <div className="w-full flex justify-start">
      <Modal title="Report issue" button_text="Report issue" submit={submit} button_view={
        <p className="w-fit text-left mb-1 pr-6 text-sm text-violet-600 cursor-pointer">Report an issue with the piece.</p>
      }>
        <div class="w-full mb-2">
          <label for="message"
            class="block mb-2 text-sm font-medium dark:text-white">What is wrong with the piece?</label>
          <textarea id="message" rows="4"
            onChange={(e) => setMessage(e.target.value)}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            value={message}
            placeholder="Please explain the issue with this piece. Thanks!"></textarea>
        </div>
      </Modal>
    </div>
  )
}

export default FlagPiece