import React from 'react';
import classNames from 'classnames';

export const Table = React.forwardRef(function Table(props, ref) {
  const { children, ...other } = props;
  return (
    <div className="w-full overflow-x-scroll md:overflow-x-auto">
      <table className="w-full whitespace-nowrap" ref={ref} {...other}>
        {children}
      </table>
    </div>
  );
});


export const TableBody = React.forwardRef(function TableBody(props, ref) {
  const { className, children, ...other } = props;

  const baseStyle = 'w-full overflow-x-scroll bg-white divide-y dark:divide-gray-700 dark:bg-gray-800 text-gray-700 dark:text-gray-400';

  const cls = classNames(baseStyle, className);

  return (
    <tbody className={cls} ref={ref} {...other}>
      {children}
    </tbody>
  );
});

export const TableCell = React.forwardRef(function TableCell(props, ref) {
  const { className, children, ...other } = props;

  const baseStyle = 'px-4 py-3'

  const cls = classNames(baseStyle, className);

  return (
    <td className={cls} ref={ref} {...other}>
      {children}
    </td>
  );
});

export const TableContainer = React.forwardRef(function TableContainer(props, ref) {
  const { className, children, ...other } = props;

  const baseStyle = 'w-full overflow-x-scroll rounded-lg ring-1 ring-black ring-opacity-5'

  const cls = classNames(baseStyle, className);

  return (
    <div className={cls} ref={ref} {...other}>
      {children}
    </div>
  );
});

export const TableFooter = React.forwardRef(function TableFooter(props, ref) {
  const { className, children, ...other } = props;

  const baseStyle = 'px-4 py-3 border-t dark:border-gray-700 bg-gray-50 text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-b-lg';

  const cls = classNames(baseStyle, className);

  return (
    <div className={cls} ref={ref} {...other}>
      {children}
    </div>
  );
});

export const TableHeader = React.forwardRef(function TableHeader(props, ref) {
  const { className, children, ...other } = props;

  const baseStyle = 'w-full overflow-x-scroll text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-white dark:text-gray-400 dark:bg-gray-800';

  const cls = classNames(baseStyle, className);

  return (
    <thead className={cls} ref={ref} {...other}>
      {children}
    </thead>
  );
});

export const TableRow = React.forwardRef(function TableRow(props, ref) {
  const { className, children, ...other } = props;

  const baseStyle = 'w-full hover:bg-gray-50 dark:hover:bg-gray-700';

  const cls = classNames(baseStyle, className);

  return (
    <tr className={cls} ref={ref} {...other}>
      {children}
    </tr>
  );
});