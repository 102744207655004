import React from 'react';
import classNames from 'classnames';

const Backdrop = React.forwardRef(function Backdrop(props, ref) {
  const { className, ...other } = props;

  const baseStyle = 'h-screen overflow-y-scroll no-scrollbar fixed inset-0 z-40 flex bg-black bg-opacity-50 items-center m-auto justify-center'

  const cls = classNames(baseStyle, className);

  return <div className={cls} ref={ref} {...other}></div>;
});

export default Backdrop;
