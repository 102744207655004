import API from ".";

const reorderAlbumPieces = (user, album, ordering) => {

  let obj = {
    "album_id": album,
    "ordering": ordering.join(',')
  }

  console.log(obj);

  return new API(user).postRequest('/reorderAlbumPieces', obj);
}

export default reorderAlbumPieces;