import React from 'react';

import AndrewHeadshot from '../assets/andrew_headshot.jpg'
import AlexHeadshot from '../assets/alex_headshot.jpg'
import SeanHeadshot from '../assets/sean_headshot.jpg'

function Testimonials() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-100 dark:border-gray-700">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4 text-gray-900 dark:text-white">Don't take our word for it</h2>
            <p className="text-xl text-gray-400">Aspiring musicians just like you are already learning with (and loving!) Subito. Read what they have to say.</p>
          </div>

          {/* Testimonials */}
          <div className="max-w-sm mx-auto grid gap-8 lg:grid-cols-3 lg:gap-6 items-start lg:max-w-none">

            <div className="flex flex-col h-full p-6 rounded-lg dark:border-0 border border-gray-100 shadow dark:bg-gray-800" data-aos="fade-up">
              <div>
                <div className="relative inline-flex flex-col mb-4">
                  <img className="rounded-full" src={SeanHeadshot} width="48" height="48" alt="Testimonial 01" />
                  <svg className="absolute top-0 right-0 -mr-3 w-6 h-5 fill-current text-violet-600" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 13.517c0-2.346.611-4.774 1.833-7.283C3.056 3.726 4.733 1.648 6.865 0L11 2.696C9.726 4.393 8.777 6.109 8.152 7.844c-.624 1.735-.936 3.589-.936 5.56v4.644H0v-4.531zm13 0c0-2.346.611-4.774 1.833-7.283 1.223-2.508 2.9-4.586 5.032-6.234L24 2.696c-1.274 1.697-2.223 3.413-2.848 5.148-.624 1.735-.936 3.589-.936 5.56v4.644H13v-4.531z" />
                  </svg>
                </div>
              </div>
              <blockquote className="text-lg text-gray-400 grow">— I use Subito primarily for sight-reading, exploring new pieces that I haven’t seen before. I really like Subito’s interface, as it allows me to easily search, find, and explore new pieces, while cataloging my activity.</blockquote>
              <div className="text-gray-700 font-medium mt-6 pt-5 border-t border-gray-100 dark:border-gray-700">
                <span className="text-black dark:text-gray-200 not-italic">Sean - </span> <a className="text-violet-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">Piano</a>
              </div>
            </div>

            <div className="flex flex-col h-full p-6 rounded-lg dark:border-0 border border-gray-100 shadow dark:bg-gray-800" data-aos="fade-up">
              <div>
                <div className="relative inline-flex flex-col mb-4">
                  <img className="rounded-full" src={AlexHeadshot} width="48" height="48" alt="Testimonial 01" />
                  <svg className="absolute top-0 right-0 -mr-3 w-6 h-5 fill-current text-violet-600" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 13.517c0-2.346.611-4.774 1.833-7.283C3.056 3.726 4.733 1.648 6.865 0L11 2.696C9.726 4.393 8.777 6.109 8.152 7.844c-.624 1.735-.936 3.589-.936 5.56v4.644H0v-4.531zm13 0c0-2.346.611-4.774 1.833-7.283 1.223-2.508 2.9-4.586 5.032-6.234L24 2.696c-1.274 1.697-2.223 3.413-2.848 5.148-.624 1.735-.936 3.589-.936 5.56v4.644H13v-4.531z" />
                  </svg>
                </div>
              </div>
              <blockquote className="text-lg text-gray-400 grow">— Although I really enjoy playing the cello, I have often struggled to maintain my interest for long periods of time necessary to practice. Subito helps me to structure my practice through drills more effectively and see tangible progress that helps motivate me.</blockquote>
              <div className="text-gray-700 font-medium mt-6 pt-5 border-t border-gray-100 dark:border-gray-700">
                <span className="text-black dark:text-gray-200 not-italic">Alex - </span> <a className="text-violet-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">Cello</a>
              </div>
            </div>

            <div className="flex flex-col h-full p-6 rounded-lg dark:border-0 border border-gray-100 shadow dark:bg-gray-800" data-aos="fade-up">
              <div>
                <div className="relative inline-flex flex-col mb-4">
                  <img className="rounded-full" src={AndrewHeadshot} width="48" height="48" alt="Testimonial 01" />
                  <svg className="absolute top-0 right-0 -mr-3 w-6 h-5 fill-current text-violet-600" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 13.517c0-2.346.611-4.774 1.833-7.283C3.056 3.726 4.733 1.648 6.865 0L11 2.696C9.726 4.393 8.777 6.109 8.152 7.844c-.624 1.735-.936 3.589-.936 5.56v4.644H0v-4.531zm13 0c0-2.346.611-4.774 1.833-7.283 1.223-2.508 2.9-4.586 5.032-6.234L24 2.696c-1.274 1.697-2.223 3.413-2.848 5.148-.624 1.735-.936 3.589-.936 5.56v4.644H13v-4.531z" />
                  </svg>
                </div>
              </div>
              <blockquote className="text-lg text-gray-400 grow">— I have been using Subito primarily as a tool to challenge me to really internalize the music I am playing. I think that, as a young musician, it is so easy to fall into the trap of learning music quickly but not rigorously. Subito allows me to do both efficiently.</blockquote>
              <div className="text-gray-700 font-medium mt-6 pt-5 border-t border-gray-100 dark:border-gray-700">
                <span className="text-black dark:text-gray-200 not-italic">Andrew - </span> <a className="text-violet-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">Cello</a>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Testimonials;
