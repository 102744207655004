import { useState, useEffect, useContext } from "react";
import saveUserInfo from "../../api/saveUserInfo";
import { AccountContext } from "../../stores/Account";
import ImageCompressor from "./ImageCompressor";
import { createImageFromInitials } from "../../components/LetterPicture";
import { primary_color } from "../../config/config";
import { getInitial } from "../../utils/string";
import StateButton from "./StateButton";
import Heading from '../components/Heading';

function UserInfo() {

  const { user, userInfo } = useContext(AccountContext);

  const [editMode, setEditMode] = useState(false);
  const [fname, setFname] = useState(null);
  const [lname, setLname] = useState(null);
  //const [bio, setBio] = useState(null);
  const [pic, setPic] = useState(null);
  const [instrument, setInstrument] = useState(null);

  useEffect(() => {
    setFname(userInfo.first_name);
    setLname(userInfo.last_name);
    setInstrument(userInfo.instrument);
    setPic(userInfo.profile_pic);
  }, [userInfo]);

  function updateProfile() {
    saveUserInfo(user, fname, lname, instrument, pic).then(() => {
      setEditMode(false);
      window.location.reload();
    })
  };

  return (
    <div class="w-full mb-4 bg-white shadow-md dark:bg-gray-800 sm:rounded-lg p-10 mt-3">
      <div class="w-full">
        <div className="flex flex-start">
          <h2 className="text-2xl font-bold text-black mb-4 dark:text-white">My Info</h2>
        </div>
        <div class="flex flex-col items-center">
          <img class="object-cover w-40 h-40 p-1 rounded-full ring-2 ring-violet-600 dark:ring-violet-600"
            src={pic || createImageFromInitials(500, getInitial(fname) + getInitial(lname), primary_color)} />
          <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white mt-2">{userInfo.email}</h5>
          <span class="text-sm text-gray-500 dark:text-gray-400">Joined {userInfo.date_joined}</span>
          <div class="flex mt-4 md:mt-6">
            <ImageCompressor setPic={setPic} className="inline-flex items-center px-4 py-2 mr-2 text-sm font-semibold text-center cursor-pointer text-white bg-violet-600 rounded-lg hover:bg-violet-700 hover:shadow focus:outline-none focus:ring-violet-300 transition-all duration-300" />
            <div onClick={() => setPic(createImageFromInitials(500, getInitial(fname) + getInitial(lname), primary_color))} class="cursor-pointer py-2 px-4 text-sm font-semibold text-gray-500 hover:text-gray-800 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-50 focus:z-10 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 transition-all duration-300 ">Delete picture</div>
          </div>
        </div> 
      </div>

      <div class="grid max-w-2xl mx-auto mt-8">

        <div class="items-center">

          <div
            class="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
            <div class="w-full">
              <label for="first_name"
                className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white">
                Your first name</label>
              {editMode ? (
                <input type="text" id="first_name"
                onChange={(e) => setFname(e.target.value)}
                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Your first name" value={fname} required />
              ) : (
                <div className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                  {fname || ""}
                </div>
              )}
            </div>

            <div class="w-full">
              <label for="last_name"
                className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white">
                Your last name</label>
              {editMode ? (
                <input type="text" id="last_name"
                value={lname}
                onChange={(e) => setLname(e.target.value)}
                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Your last name" required />
              ) : (
                <div className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                  {lname || ""}
                </div>
              )}
            </div>
          </div>

          <div class="mb-2 sm:mb-6">
            <label for="profession"
              className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white">
              Instrument</label>
            {editMode ? (
              <select id="instruments"
                onChange={(e) => setInstrument(e.target.value)}
                value={instrument}
                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                <option selected>Select an instrument</option>
                <option value="piano">Piano</option>
                {/*<option value="violin">Violin</option>
                <option value="cello">Cello</option>*/}
              </select>
            ) : (
              <div className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                {instrument ? instrument.charAt(0).toUpperCase() + instrument.slice(1).toLowerCase() : ""}
              </div>
            )}
          </div>

          {/*<div class="mb-6">
                <label for="message"
                  class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Bio</label>
                <textarea id="message" rows="4"
                  onChange={(e) => setBio(e.target.value)}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={bio}
                  placeholder="Your bio"></textarea>
              </div>*/}

          <div class="flex justify-end">
            {/*<StateButton
              activeStyle="bg-green-600 hover:bg-green-700"
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-violet-600 rounded-lg hover:bg-violet-700 focus:outline-none focus:ring-violet-300"
              onClick={updateProfile}>
              Save
            </StateButton>*/}
            {!editMode && <button onClick={() => setEditMode(true)}
              class="inline-flex items-center justify-center w-full px-4 py-2 mr-2 text-sm font-semibold text-center text-gray-800 bg-gray-100 rounded-lg hover:bg-gray-200 hover:shadow-sm focus:outline-none focus:ring-violet-300 transition-all duration-300">Edit Profile</button>}
            <button onClick={updateProfile}
              class="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-semibold text-center text-white bg-violet-600 rounded-lg hover:bg-violet-700 hover:shadow-sm focus:outline-none focus:ring-violet-300 transition-all duration-300">Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserInfo