export function daysSinceDate(dateString: string): number {
  // Parse the input date string
  const [datePart, timePart] = dateString.split(' ');
  const [month, day, year] = datePart.split('/').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);

  // Create Date objects for the input date and today
  const inputDate = new Date(2000 + year, month - 1, day, hours, minutes, seconds);
  const today = new Date();

  // Calculate the difference in milliseconds
  const differenceMs = today.getTime() - inputDate.getTime();

  // Convert milliseconds to days and round down
  const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  return days;
}