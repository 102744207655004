import { useState, useRef, useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import file from './dvorak_concerto.pdf';
import useContainerSize from '../../hooks/useContainerSize';
import PageTurner from './PageTurner';
import PDFLoadingView from './PDFLoadingView';
import KeyboardShortcuts from './KeyboardShortcuts';

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

export default function PDFViewer(props) {
  const containerRef = useRef(null);
  const { width } = useContainerSize(containerRef);

  const [activePage, setActivePage] = useState(1);
  const [numPages, setNumPages] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    setActivePage(1);
  }, [props.src])

  console.log("pdf viewer...")
  return (
    <>
      <div className="w-full overflow-auto border border-gray-300 my-4 relative bg-white" style={{ height: props.height ? props.height : '60vh' }} ref={containerRef}>
        <Document file={props.src} options={options} onLoadSuccess={onDocumentLoadSuccess} loading={<PDFLoadingView width={'100vw'} height={'100vh'} />}>
          <Page
            pageNumber={activePage}
            width={width}
            loading={<PDFLoadingView width={'100vw'} height={'100vh'} />}
          />
        </Document>
        <div className="w-full relative px-4 bottom-4 z-10">
          <PageTurner totalPages={numPages} activePage={activePage} setActivePage={setActivePage} />
        </div>
        {props.children}
      </div>
      <KeyboardShortcuts />
    </>
  );
}