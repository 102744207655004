import { useRef, useEffect, useCallback } from "react";

const useClickOutside = (handler, isDisabled = false) => {
  const ref = useRef();
  const handlerRef = useRef(handler);
  const isDisabledRef = useRef(isDisabled);

  // Update the handler ref when the handler changes
  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  // Update the isDisabled ref when isDisabled changes
  useEffect(() => {
    isDisabledRef.current = isDisabled;
  }, [isDisabled]);

  const handleClickOutside = useCallback((event) => {
    if (!isDisabledRef.current && ref.current && !ref.current.contains(event.target)) {
      handlerRef.current();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return ref;
};

export default useClickOutside;