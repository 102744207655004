import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Backdrop from './Backdrop';
import Transition from './Transition';
import FocusLock from 'react-focus-lock';
import classNames from 'classnames';

export const Dialog = React.forwardRef(function Dialog(props, ref) {
  const { children, onClose, isOpen, ...other } = props;

  const baseStyle = props.full_page ?
    'w-screen h-screen overflow-y-scroll overflow-x-hidden bg-white dark:bg-gray-800 m-0' :
    'w-full px-6 py-4 bg-white rounded-lg dark:bg-gray-800 m-auto sm:max-w-xl'

  function handleEsc(e) {
    if (e.key === 'Esc' || e.key === 'Escape') {
      onClose();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEsc, { capture: true });
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const modalComponent = (
    <Transition show={isOpen}>
      <>
        <Transition
          enter="transition ease-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Backdrop onClick={onClose}>
            <Transition
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 transform translate-y-1/2"
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0  transform translate-y-1/2"
            >
              <div
                className={baseStyle}
                role="dialog"
                onClick={(e) => e.stopPropagation()}
                ref={ref}
                {...other}
              >
                <FocusLock returnFocus>
                  <header className="flex justify-end">
                    <button
                      className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                      aria-label="close"
                      onClick={onClose}
                    >
                      <svg
                        className="w-4 h-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        role="img"
                        aria-hidden="true"
                      >
                        <path
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </header>
                  {children}
                </FocusLock>
              </div>
            </Transition>
          </Backdrop>
        </Transition>
      </>
    </Transition>
  );

  return mounted ? createPortal(modalComponent, document.body) : null;
});

export const ModalHeader = React.forwardRef(function ModalHeader(props, ref) {
  const { children, className, ...other } = props;

  const baseStyle = 'mb-4 text-xl font-semibold dark:text-white'

  const cls = classNames(baseStyle, className);

  return (
    <p className={cls} ref={ref} {...other}>
      {children}
    </p>
  );
});

export const ModalBody = React.forwardRef(function ModalBody(props, ref) {
  const { children, className, ...other } = props;

  const baseStyle = 'mb-4 text-sm text-gray-700 dark:text-gray-400'

  const cls = classNames(baseStyle, className);

  return (
    <div className={cls} ref={ref} {...other}>
      {children}
    </div>
  );
});

export const ModalFooter = React.forwardRef(function ModalFooter(props, ref) {
  const { children, className, ...other } = props;

  const baseStyle = 'flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-2 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800 rounded-b-lg'

  const cls = classNames(baseStyle, className);

  return (
    <footer className={cls} ref={ref} {...other}>
      {children}
    </footer>
  );
});

function Modal(props) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (props.fullPageOpen) {
      openModal();
    }
  }, [props.fullPageOpen]);

  useEffect(() => {
    if (props.onModalOpen && isModalOpen) {
      props.onModalOpen();
    } else if (props.onModalClose && !isModalOpen) {
      props.onModalClose();
    }
  }, [isModalOpen])

  return (
    <>
      <div onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        openModal()
      }}>
        {props.button_view}
      </div>
      <Dialog isOpen={isModalOpen} onClose={closeModal} full_page={props.full_page}>
        {props.full_page ?
          props.children :
          <>
            <ModalHeader>{props.title}</ModalHeader>
            <ModalBody>
              {props.children}
            </ModalBody>
            <ModalFooter>
              <div className="w-full flex flex-row space-x-4 justify-start">
                {!props.hide_button && <button onClick={props.submit} className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-violet-600 rounded-lg hover:bg-violet-700 focus:outline-none focus:ring-violet-300 me-2">
                  {props.button_text}
                </button>}
                <button onClick={closeModal} className="py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-50 focus:z-10 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                  Cancel
                </button>
              </div>
            </ModalFooter>
          </>}
      </Dialog>
    </>
  )
}

export default Modal
