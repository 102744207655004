import API from ".";

const bulkPieceUpload = (user, file_names, file_ids) => {

  let obj = {
    "file_names": file_names,
    "file_ids": file_ids
  }

  return new API(user).postRequest('/bulkPieceUpload', obj);
}

export default bulkPieceUpload;