const NewListCard = () => {
  return (
    <div className="group">
      <div className="cursor-pointer w-full sm:w-56 h-72 bg-white dark:bg-gray-800 rounded-lg shadow-md flex flex-col items-center justify-center p-8 group-hover:bg-gray-50 dark:group-hover:bg-gray-900 transition-all duration-300">
        <div className="w-16 h-16 bg-gray-200 dark:bg-gray-700 rounded-full flex items-center justify-center group-hover:bg-gray-300 dark:group-hover:bg-gray-600 transition-colors duration-200">
          <svg className="w-8 h-8 text-gray-700 dark:text-gray-300" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.75v14.5M19.25 12H4.75"></path>
          </svg>
        </div>
        <div className="mt-4 text-lg font-semibold text-gray-900 dark:text-white select-none">Add New List</div>
      </div>
    </div>
  );
};

export default NewListCard;